/* eslint-disable no-underscore-dangle */
import { Descriptions, Card, List } from 'antd';

import { useCallback, useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Container } from './styles';
import { ActionsType, transform } from '../../../helper/action-transform';
import { useApi } from '../../../hooks/api';
import ILogDTO from '../../../dtos/ILogDTO';

interface ILogData extends ILogDTO {
  formattedDate: string;
}

const Log: React.FC = () => {
  const [log, setLog] = useState<ILogData>();

  const { id } = useParams<{ id: string }>();

  const { api } = useApi();

  const getLog = useCallback(async (): Promise<void> => {
    const response = await api.get<ILogDTO>(`/users/logs/${id}`);
    const data = {
      ...response.data,
      action: transform(response.data.action as ActionsType),
      formattedDate: format(
        parseISO(response.data.createdAt),
        'dd/MM/yyyy HH:mm:ss',
      ),
    };
    setLog(data);
  }, [api, id]);

  useEffect(() => {
    getLog();
  }, [getLog]);

  return (
    <Container>
      <Descriptions title="Informações do Log">
        <Descriptions.Item
          label="Data/hora"
          labelStyle={{ fontWeight: 'bold' }}
        >
          {log?.formattedDate}
        </Descriptions.Item>
        <Descriptions.Item label="Usuário" labelStyle={{ fontWeight: 'bold' }}>
          {log?.user.name}
        </Descriptions.Item>
        <Descriptions.Item
          label="Matrícula"
          labelStyle={{ fontWeight: 'bold' }}
        >
          {log?.user.enrollment}
        </Descriptions.Item>
        <Descriptions.Item label="Ação" labelStyle={{ fontWeight: 'bold' }}>
          {log?.action}
        </Descriptions.Item>
      </Descriptions>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        dataSource={[
          {
            title: 'Dados da requisição',
            content: JSON.stringify(log?.request, null, 2),
          },
          {
            title: 'Dados de resposta',
            content: JSON.stringify(log?.data, null, 2),
          },
        ]}
        renderItem={(item) => (
          <List.Item>
            <Card title={item.title}>
              <pre>{item.content}</pre>
            </Card>
          </List.Item>
        )}
      />
    </Container>
  );
};

export default Log;
