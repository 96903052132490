import styled, { createGlobalStyle } from 'styled-components';
import { Layout } from 'antd';
import 'antd/dist/antd';

const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  body{
    background: linear-gradient(305.42deg, #FFFFFF 0%, #E5E5E5 100%);
    -webkit-font-smoothing: antialiased
  }
  body, input, button{
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
  }
  h1, h2, h3, h4, h5, h6, strong{
    font-weight: 500;
  }
  h3{
    font-size: 1.75em;
  }
  h2{
    font-size: 2em;
  }
  h1{
    font-size: 2.75em;
  }
  button{
    cursor: pointer;
  }
`;

const SiteLayout = styled(Layout)`
  min-height: 100vh;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  > button {
    &:first-child {
      margin-right: 10px;
    }
  }
`;

export { GlobalStyle, SiteLayout };
