import styled from 'styled-components';

import { Layout } from 'antd';

const { Content } = Layout;

export const Container = styled(Content)`
  border-radius: 8px;
  background: #fff;
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px;
`;
