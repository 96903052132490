import { Form, Input, Button } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ICostCenterDTO from '../../../dtos/ICostCenterDTO';
import { useApi } from '../../../hooks/api';
import { useNotification } from '../../../hooks/notification';
import { Container } from './styles';

const validate = {
  required: '${label} é obrigatório',
};

const EditCostCenter: React.FC = () => {
  const { api } = useApi();
  const { openNotificationWithIcon } = useNotification();

  const [costCenter, setCostCenter] = useState<ICostCenterDTO>();

  const [form] = Form.useForm();

  const { id } = useParams<{ id: string }>();

  const loadData = useCallback(async (): Promise<void> => {
    const costCenterResponse = await api.get<ICostCenterDTO>(
      `costCenters/${id}`,
    );

    setCostCenter(costCenterResponse.data);
  }, [api, id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = async (data: ICostCenterDTO): Promise<void> => {
    try {
      await api.put(`costCenters/${id}`, data);
      openNotificationWithIcon('success', {
        title: 'Centro de custo',
        content: 'Centro de custo alterado com sucesso!',
      });
    } catch (error) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data.messageResponse;
      }
      openNotificationWithIcon('error', {
        title: 'Centro de custo',
        content: errorMessage || 'Erro ao alterar centro de custo!',
      });
    }
  };

  return (
    <Container>
      <h1>Alterar Centro de Custo</h1>
      {costCenter && (
        <Form
          size="large"
          labelCol={{ flex: '110px' }}
          labelAlign="left"
          wrapperCol={{ flex: 1 }}
          labelWrap
          colon={false}
          form={form}
          initialValues={costCenter}
          validateMessages={validate}
          onFinish={handleSubmit}
        >
          <Form.Item
            name={['code']}
            label="Código"
            rules={[{ required: true }]}
            wrapperCol={{ span: 12 }}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={['description']}
            label="Descrição"
            rules={[{ required: true }]}
            wrapperCol={{ span: 12 }}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form>
      )}
    </Container>
  );
};

export default EditCostCenter;
