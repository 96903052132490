import { Form, Input, Button } from 'antd';
import { AxiosError } from 'axios';
import IMeasurementUnitDTO from '../../../../dtos/IMeasurementUnitDTO';
import { useApi } from '../../../../hooks/api';
import { useNotification } from '../../../../hooks/notification';
import { Container } from './styles';

const validate = {
  required: '${label} é obrigatório',
};

const NewMeasurementUnits: React.FC = () => {
  const { api } = useApi();
  const { openNotificationWithIcon } = useNotification();
  const [form] = Form.useForm();

  const handleSubmit = async (data: IMeasurementUnitDTO): Promise<void> => {
    try {
      await api.post('measurementUnits', data);

      openNotificationWithIcon('success', {
        title: 'Unidade de medida',
        content: 'Unidade de medida cadastrada com sucesso!',
      });
      form.resetFields();
    } catch (error) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data.messageResponse;
      }
      openNotificationWithIcon('error', {
        title: 'Unidade de medida',
        content: errorMessage || 'Erro ao cadastrar a unidade de medida!',
      });
    }
  };

  return (
    <Container>
      <h1>Cadastro de Unidade de Medida</h1>
      <Form
        size="large"
        labelCol={{ flex: '110px' }}
        labelAlign="left"
        wrapperCol={{ flex: 1 }}
        labelWrap
        colon={false}
        validateMessages={validate}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          name={['name']}
          label="Nome"
          rules={[{ required: true }]}
          wrapperCol={{ span: 12 }}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['description']}
          label="Descrição"
          rules={[{ required: true }]}
          wrapperCol={{ span: 12 }}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Salvar
        </Button>
      </Form>
    </Container>
  );
};

export default NewMeasurementUnits;
