import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ICostCenterDTO from '../../../dtos/ICostCenterDTO';
import { useApi } from '../../../hooks/api';
import { Actions, Container } from './styles';

const columns = [
  {
    title: 'Código',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
  },
];

const ListCostCenters: React.FC = () => {
  const { api } = useApi();
  const [costCenters, setCostCenters] = useState<ICostCenterDTO[]>();
  const [search, setSearch] = useState('');
  const [xCount, setXCount] = useState(0);

  const history = useHistory();

  const listCostCenters = useCallback(
    async (skip = 0): Promise<void> => {
      const response = await api.get<ICostCenterDTO[]>(
        `costCenters?search=${search}&skip=${skip}`,
      );

      const data = response.data.map((costCenter) => ({
        key: costCenter.id,
        ...costCenter,
      }));

      setXCount(Number(response.headers['x-count']) || 0);
      setCostCenters(data);
    },
    [api, search],
  );

  useEffect(() => {
    listCostCenters();
  }, [listCostCenters]);

  const handleNavigate = (to: string): void => {
    history.push(to);
  };

  return (
    <Container>
      <h1>Centros de Custo</h1>
      <Input.Search
        placeholder="Buscar por código"
        onSearch={(value) => setSearch(value)}
        style={{ width: 400, marginBottom: 20 }}
      />
      <Table
        columns={[
          ...columns,
          {
            title: '#',
            dataIndex: '#',
            key: '#',
            render: (_: string, record: ICostCenterDTO) => {
              return (
                <Actions>
                  <Button
                    title="Editar"
                    type="primary"
                    onClick={() =>
                      handleNavigate(`/centros-custo/${record.id}`)
                    }
                  >
                    <EditOutlined />
                  </Button>
                  {/* <Button danger title="Excluir">
                    <DeleteFilled />
                  </Button> */}
                </Actions>
              );
            },
          },
        ]}
        dataSource={costCenters}
        pagination={{
          total: xCount,
          onChange: (page) => listCostCenters((page - 1) * 10),
          pageSizeOptions: [10],
        }}
      />
    </Container>
  );
};

export default ListCostCenters;
