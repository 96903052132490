import { EditOutlined } from '@ant-design/icons';
import { Input, Button, Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IUserDTO from '../../../dtos/IUserDTO';
import { useApi } from '../../../hooks/api';
import { Actions, Container } from './styles';

const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    key: 'type',
    render: (type: IUserDTO['type']) =>
      type === 'ADMIN' ? 'Administrador' : 'Mecânico',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Matrícula',
    dataIndex: 'enrollment',
    key: 'enrollment',
  },
];

const ListUsers: React.FC = () => {
  const { api } = useApi();
  const history = useHistory();
  const [users, setUsers] = useState<IUserDTO[]>();
  const [xCount, setXCount] = useState(0);
  const [search, setSearch] = useState('');

  const listUsers = useCallback(
    async (skip = 0): Promise<void> => {
      const response = await api.get<IUserDTO[]>(
        `users?search=${search}&skip=${skip}`,
      );

      const data = response.data.map((user) => ({
        key: user.id,
        ...user,
      }));

      setXCount(Number(response.headers['x-count']) || 0);
      setUsers(data);
    },
    [search, api],
  );

  useEffect(() => {
    listUsers();
  }, [listUsers]);

  const handleNavigate = (to: string): void => {
    history.push(to);
  };

  return (
    <Container>
      <h1>Usuários</h1>
      <Input.Search
        placeholder="Buscar por matricula ou nome"
        onSearch={(value) => setSearch(value)}
        style={{ width: 600, marginBottom: 20 }}
      />
      <Table
        columns={[
          ...columns,
          {
            title: '#',
            dataIndex: '#',
            key: '#',
            render: (_: string, record: IUserDTO) => {
              return (
                <Actions>
                  <Button
                    title="Editar"
                    type="primary"
                    onClick={() => handleNavigate(`/usuarios/${record.id}`)}
                  >
                    <EditOutlined />
                  </Button>
                  {/* <Button danger title="Excluir">
                    <DeleteFilled />
                  </Button> */}
                </Actions>
              );
            },
          },
        ]}
        dataSource={users}
        pagination={{
          total: xCount,
          onChange: (page) => listUsers((page - 1) * 10),
          pageSizeOptions: [10],
        }}
      />
    </Container>
  );
};

export default ListUsers;
