import { Card, Col, Row, Space, Statistic, Timeline } from 'antd';
import Table from 'antd/lib/table';
import {
  LoginOutlined,
  PlusOutlined,
  StopOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { Container, CardLayout } from './styles';
import { useApi } from '../../hooks/api';
import IMaterialDTO from '../../dtos/IMaterialDTO';

const columns = [
  {
    title: 'Material',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Quantidade',
    dataIndex: 'quantity',
    key: 'quantity',
  },
];

const Dashboard: React.FC = () => {
  const { api } = useApi();

  const [xCount, setXCount] = useState(0);
  const [users, setUsers] = useState(0);
  const [materials, setMaterials] = useState<IMaterialDTO[]>();
  const [completeMaterials, setCompleteMaterials] = useState(0);
  const [incompleteMaterials, setIncompleteMaterials] = useState(0);

  const listMaterials = useCallback(
    async (skip = 0) => {
      const response = await api.get<IMaterialDTO[]>(
        `/materials?take=20&order=quantity:asc&paginate=true&${skip}`,
      );

      const data = response.data.map((material) => ({
        key: material.id,
        ...material,
      }));

      setXCount(Number(response.headers['x-count']) || 0);
      setMaterials(data);
    },
    [api],
  );

  const loadData = useCallback(async (): Promise<void> => {
    const usersPromise = api.get<{ count: number }>('/users/count');
    const completeMaterialsPromise = api.get<{ count: number }>(
      '/materials/count',
    );
    const incompleteMaterialsPromise = api.get<{ count: number }>(
      '/materials/count?nullableLocations=true',
    );

    const [usersCount, materialsCount, incompleteMaterialsCount] =
      await Promise.all([
        usersPromise,
        completeMaterialsPromise,
        incompleteMaterialsPromise,
      ]);

    setUsers(usersCount.data.count);
    setCompleteMaterials(materialsCount.data.count);
    setIncompleteMaterials(incompleteMaterialsCount.data.count);
  }, [api]);

  useEffect(() => {
    loadData();
    listMaterials();
  }, [loadData, listMaterials]);

  return (
    <Container>
      <Row gutter={16}>
        <Col span={8}>
          <CardLayout bordered title="Usuários ativos">
            <Space
              align="center"
              style={{
                justifyContent: 'space-between',
                width: '100%',
                padding: '0 20px',
              }}
            >
              <Statistic value={users} valueStyle={{ color: '#dc3545' }} />
              <UserOutlined />
            </Space>
          </CardLayout>
        </Col>
        <Col span={8}>
          <CardLayout
            bordered
            title="Materiais Cad. Completo"
            backgroundColor="#0d6efd"
          >
            <Space
              align="center"
              style={{
                justifyContent: 'space-between',
                width: '100%',
                padding: '0 20px',
              }}
            >
              <Statistic
                value={completeMaterials}
                valueStyle={{ color: '#0d6efd' }}
              />
              <ToolOutlined />
            </Space>
          </CardLayout>
        </Col>
        <Col span={8}>
          <CardLayout
            bordered
            title="Materiais Cad. Incompleto"
            backgroundColor="#4650dd"
          >
            <Space
              align="center"
              style={{
                justifyContent: 'space-between',
                width: '100%',
                padding: '0 20px',
              }}
            >
              <Statistic
                value={incompleteMaterials}
                valueStyle={{ color: '#4650dd' }}
              />
              <StopOutlined />
            </Space>
          </CardLayout>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Card
            bordered
            type="inner"
            title="Top 20 materiais com menor estoque"
          >
            <Table
              dataSource={materials}
              columns={columns}
              pagination={{
                total: xCount,
                onChange: (page) => listMaterials((page - 1) * 10),
                pageSizeOptions: [10],
              }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered type="inner" title="Últimas atualizações">
            <Timeline
              mode="alternate"
              items={[
                {
                  dot: <PlusOutlined />,
                  color: '#28a745',
                  label: '2015-09-01',
                  children: 'Admin retirou materiais',
                },
                {
                  dot: <LoginOutlined />,
                  label: '2015-09-01 09:12:11',
                  children: 'Mecânico se logou na plataforma',
                },
                {
                  dot: <PlusOutlined />,
                  color: '#28a745',
                  label: '2015-09-01 09:12:11',
                  children: 'Mecânico2 retirou materiais',
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
