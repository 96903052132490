import { EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IMeasurementUnitDTO from '../../../../dtos/IMeasurementUnitDTO';
import { useApi } from '../../../../hooks/api';
import { Actions, Container } from './styles';

const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
  },
];

const ListMeasurementUnits: React.FC = () => {
  const { api } = useApi();
  const history = useHistory();
  const [measurementUnits, setMeasurementUnits] =
    useState<IMeasurementUnitDTO[]>();
  const [xCount, setXCount] = useState<number>();

  const listMeasurementUnits = useCallback(
    async (skip = 0): Promise<void> => {
      const response = await api.get<IMeasurementUnitDTO[]>(
        `measurementUnits?skip=${skip}`,
      );

      const data = response.data.map((measurementUnit) => ({
        key: measurementUnit.id,
        ...measurementUnit,
      }));

      setXCount(Number(response.headers['x-count']) || 0);
      setMeasurementUnits(data);
    },
    [api],
  );

  useEffect(() => {
    listMeasurementUnits();
  }, [listMeasurementUnits]);

  const handleNavigate = (to: string): void => {
    history.push(to);
  };

  return (
    <Container>
      <h1>Unidades de Medida</h1>
      <Table
        columns={[
          ...columns,
          {
            title: '#',
            dataIndex: '#',
            key: '#',
            render: (_: string, record: IMeasurementUnitDTO) => {
              return (
                <Actions>
                  <Button
                    title="Editar"
                    type="primary"
                    onClick={() =>
                      handleNavigate(`/unidades-medida/${record.id}`)
                    }
                  >
                    <EditOutlined />
                  </Button>
                  {/* <Button danger title="Excluir" disabled>
                    <DeleteFilled />
                  </Button> */}
                </Actions>
              );
            },
          },
        ]}
        dataSource={measurementUnits}
        pagination={{
          total: xCount,
          onChange: (page) => listMeasurementUnits((page - 1) * 10),
          pageSizeOptions: [10],
        }}
      />
    </Container>
  );
};

export default ListMeasurementUnits;
