import Sider from '../../../components/Sider';
import { SiteLayout } from '../../../styles/global';

type Props = {
  children: React.ReactElement;
};

const DefaultLayout: React.FC<Props> = ({ children }) => (
  <SiteLayout>
    <Sider>
      <div style={{ padding: '20px' }}>{children}</div>
    </Sider>
  </SiteLayout>
);

export default DefaultLayout;
