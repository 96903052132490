import { Form, Input, Button, Select } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import ICostCenterDTO from '../../../dtos/ICostCenterDTO';
import IDepositDTO from '../../../dtos/IDepositDTO';
import IDrawerDTO from '../../../dtos/IDrawerDTO';
import ILocationDTO from '../../../dtos/ILocationDTO';
import IMaterialDTO from '../../../dtos/IMaterialDTO';
import IMaterialType from '../../../dtos/IMaterialTypeDTO';
import IMeasurementUnitDTO from '../../../dtos/IMeasurementUnitDTO';
import { useApi } from '../../../hooks/api';
import { useNotification } from '../../../hooks/notification';
import { Container } from './styles';

const validate = {
  required: '${label} é obrigatório',
};

const { Option } = Select;

const NewMaterial: React.FC = () => {
  const { api } = useApi();
  const { openNotificationWithIcon } = useNotification();
  const [measurementUnits, setMeasurementUnits] =
    useState<IMeasurementUnitDTO[]>();
  const [materialTypes, setMaterialTypes] = useState<IMaterialType[]>();
  const [locations, setLocations] = useState<ILocationDTO[]>();
  const [drawers, setDrawers] = useState<IDrawerDTO[]>();
  const [deposits, setDeposits] = useState<IDepositDTO[]>();
  const [costCenters, setCostCenters] = useState<ICostCenterDTO[]>();
  const [compartments, setCompartments] = useState<number[]>();

  const [form] = Form.useForm();

  const loadData = useCallback(async (): Promise<void> => {
    const measurementUnitsResponse = await api.get(
      'measurementUnits?paginate=false',
    );
    const materialTypesResponse = await api.get('materialTypes?paginate=false');
    const locationsResponse = await api.get('locations?paginate=false');
    const costCentersResponse = await api.get('costCenters?paginate=false');
    const depositsResponse = await api.get('deposits?paginate=false');

    setMeasurementUnits(measurementUnitsResponse.data);
    setMaterialTypes(materialTypesResponse.data);
    setLocations(locationsResponse.data);
    setDeposits(depositsResponse.data);
    setCostCenters(costCentersResponse.data);
  }, [api]);

  const onSetLocation = (location_id: string): void => {
    const location = locations?.find((l) => l.id === location_id);
    setDrawers(location?.drawers);
  };

  const onSetDrawer = (drawer_id: string): void => {
    const drawer = drawers?.find((d) => d.id === drawer_id);

    const allCompartments: number[] = [];

    if (drawer?.compartments) {
      for (let index = 1; index <= drawer.compartments; index += 1) {
        allCompartments.push(index);
      }
    }
    setCompartments(allCompartments);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = async (data: IMaterialDTO): Promise<void> => {
    try {
      await api.post('materials', data);
      openNotificationWithIcon('success', {
        title: 'Material',
        content: 'Material cadastrado com sucesso!',
      });
      form.resetFields();
    } catch (error) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data.messageResponse;
      }
      openNotificationWithIcon('error', {
        title: 'Material',
        content: errorMessage || 'Erro ao cadastrar o material!',
      });
    }
  };

  return (
    <Container>
      <h1>Cadastro de Material</h1>
      <Form
        size="large"
        labelCol={{ flex: '110px' }}
        labelAlign="left"
        wrapperCol={{ flex: 1 }}
        labelWrap
        colon={false}
        form={form}
        validateMessages={validate}
        onFinish={handleSubmit}
      >
        <Form.Item
          name={['code']}
          label="Código"
          rules={[{ required: true }]}
          wrapperCol={{ span: 4 }}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['description']}
          label="Descrição"
          rules={[{ required: true }]}
          wrapperCol={{ span: 6 }}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['measurementUnit', 'id']}
          label="Un. Medida"
          rules={[{ required: true }]}
          wrapperCol={{ span: 2 }}
          hasFeedback
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children?.toString() ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {measurementUnits?.map((measurementUnit) => (
              <Option key={measurementUnit.id} value={measurementUnit.id}>
                {measurementUnit.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={['materialType', 'id']}
          label="Tipo"
          rules={[{ required: true }]}
          wrapperCol={{ span: 4 }}
          hasFeedback
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children?.toString() ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {materialTypes?.map((materialType) => (
              <Option key={materialType.id} value={materialType.id}>
                {materialType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={['deposit', 'id']}
          label="Depósito"
          rules={[{ required: true }]}
          wrapperCol={{ span: 4 }}
          hasFeedback
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children?.toString() ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {deposits?.map((deposit) => (
              <Option key={deposit.id} value={deposit.id}>
                {deposit.description}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={['costCenter', 'id']}
          label="Centro custo"
          rules={[{ required: true }]}
          wrapperCol={{ span: 4 }}
          hasFeedback
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children?.toString() ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {costCenters?.map((costCenter) => (
              <Option key={costCenter.id} value={costCenter.id}>
                {costCenter.code}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={['quantity']}
          label="Quantidade"
          rules={[
            { required: true },
            {
              validator: (_, quantity) => {
                if (Number(quantity) < 0) {
                  return Promise.reject(new Error('Quantidade inferior a 0'));
                }

                return Promise.resolve(true);
              },
            },
          ]}
          wrapperCol={{ span: 2 }}
          hasFeedback
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name={['location', 'id']}
          label="Localização"
          rules={[{ required: true }]}
          wrapperCol={{ span: 4 }}
          hasFeedback
        >
          <Select
            showSearch
            optionFilterProp="children"
            onChange={(value) => onSetLocation(value)}
            filterOption={(input, option) =>
              (option?.children?.toString() ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {locations?.map((location) => (
              <Option key={location.id} value={location.id}>
                {location.description}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {drawers && drawers.length > 0 && (
          <>
            <Form.Item
              name={['drawer', 'id']}
              label="Gaveta"
              rules={[{ required: true }]}
              wrapperCol={{ span: 2 }}
              hasFeedback
            >
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(value) => onSetDrawer(value)}
                filterOption={(input, option) =>
                  (option?.children?.toString() ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {drawers?.map((drawer) => (
                  <Option key={drawer.id} value={drawer.id}>
                    {drawer.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name={['compartment']}
              label="Compartimento"
              rules={[{ required: true }]}
              wrapperCol={{ span: 2 }}
              hasFeedback
            >
              <Select>
                {compartments?.map((compartment) => (
                  <Option key={compartment} value={compartment}>
                    {compartment}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
        <Button type="primary" htmlType="submit">
          Salvar
        </Button>
      </Form>
    </Container>
  );
};

export default NewMaterial;
