import { Form, Input, Button } from 'antd';
import { AxiosError } from 'axios';
import IDepositDTO from '../../../dtos/IDepositDTO';
import { useApi } from '../../../hooks/api';
import { useNotification } from '../../../hooks/notification';
import { Container } from './styles';

const validate = {
  required: '${label} é obrigatório',
};

const NewDeposits: React.FC = () => {
  const { api } = useApi();
  const { openNotificationWithIcon } = useNotification();
  const [form] = Form.useForm();

  const handleSubmit = async (data: IDepositDTO): Promise<void> => {
    try {
      await api.post('deposits', data);
      openNotificationWithIcon('success', {
        title: 'Depósito',
        content: 'Depósito cadastrado com sucesso!',
      });
      form.resetFields();
    } catch (error) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data.messageResponse;
      }
      openNotificationWithIcon('error', {
        title: 'Depósito',
        content: errorMessage || 'Erro ao cadastrar o depósito!',
      });
    }
  };

  return (
    <Container>
      <h1>Cadastro de Depósito</h1>
      <Form
        size="large"
        labelCol={{ flex: '110px' }}
        labelAlign="left"
        wrapperCol={{ flex: 1 }}
        labelWrap
        colon={false}
        form={form}
        validateMessages={validate}
        onFinish={handleSubmit}
      >
        <Form.Item
          name={['code']}
          label="Código"
          rules={[{ required: true }]}
          wrapperCol={{ span: 12 }}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['description']}
          label="Descrição"
          rules={[{ required: true }]}
          wrapperCol={{ span: 12 }}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Salvar
        </Button>
      </Form>
    </Container>
  );
};

export default NewDeposits;
