import styled from 'styled-components';
import { Layout } from 'antd';

const { Header: AntHeader } = Layout;

export const Logo = styled.div`
  margin: 16px;
  > img {
    width: 100%;
    height: 55px;
  }
`;

export const Header = styled(AntHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  padding: 0 24px;
  > span {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #1890ff;
    }
  }

  > div {
    display: flex;
    align-items: center;

    b {
      padding-right: 15px;
      font-size: 1.5rem;
    }
    span {
      font-size: 20px;
      cursor: pointer;
      color: #ee4d47;
    }
  }
`;
