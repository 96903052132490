/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
import {
  SearchOutlined,
  InboxOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Button, UploadProps, Table, Tag } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import socketio from 'socket.io-client';

import IMaterialsFileDTO from '../../../dtos/IMaterialsFileDTO';
import { useAuth } from '../../../hooks/auth';
import { Actions, Container } from './styles';
import { useFetch } from '../../../hooks/useFetch';
import { useNotification } from '../../../hooks/notification';
import { useApi } from '../../../hooks/api';

const fileStatusEnum = {
  uploaded: 'enviado',
  processing: 'processando',
  processed: 'processado',
  error: 'erro',
};

const columns = [
  {
    title: 'Nome',
    dataIndex: 'filename',
    key: 'filename',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (fileStatus: 'uploaded' | 'processing' | 'processed' | 'error') => {
      let color;
      let icon;
      switch (fileStatus) {
        case 'uploaded':
          color = 'warning';
          icon = <ExclamationCircleOutlined />;
          break;
        case 'processing':
          color = 'processing';
          icon = <SyncOutlined spin />;
          break;
        case 'processed':
          color = 'success';
          icon = <CheckCircleOutlined />;
          break;
        case 'error':
          color = 'error';
          icon = <CloseCircleOutlined />;
          break;
        default:
          color = 'processing';
          break;
      }
      return (
        <Tag color={color} icon={icon}>
          {fileStatusEnum[fileStatus]}
        </Tag>
      );
    },
  },
];

const MaterialImport: React.FC = () => {
  const { api, baseURL } = useApi();
  const { openNotificationWithIcon } = useNotification();
  const { user, token } = useAuth();
  const [skip, setSkip] = useState(0);

  const props = useMemo<UploadProps>(
    () => ({
      name: 'file',
      multiple: false,
      action: `${baseURL}/materials/upload`,
      method: 'PATCH',
      onChange(info) {
        const {
          file: { status },
        } = info;
        if (status === 'done') {
          openNotificationWithIcon('success', {
            title: 'Importação de materiais',
            content: `Arquivo ${info.file.name} importado com sucesso!`,
          });
        } else if (status === 'error') {
          openNotificationWithIcon('error', {
            title: 'Importação de materiais',
            content: `Ocorreu um erro ao importar o arquivo ${info.file.name}!`,
          });
        }
      },
      onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
      },
    }),
    [baseURL, openNotificationWithIcon],
  );

  const [draggerProps, setDraggerProps] = useState(props);

  const [files, setFiles] = useState<IMaterialsFileDTO[]>([]);
  const [xCount, setXCount] = useState(0);
  const { data } = useFetch<IMaterialsFileDTO[]>(
    `/materials/files?skip=${skip}`,
  );

  useEffect(() => {
    const filesData = data?.map((d: IMaterialsFileDTO) => ({
      key: d._id,
      ...d,
    }));

    setFiles(filesData);
  }, [data]);

  const history = useHistory();

  const listFiles = useCallback(
    async (skipValue = 0): Promise<void> => {
      const response = await api.get<IMaterialsFileDTO[]>(
        `/materials/files?skip=${skipValue}`,
      );

      const filesData = response.data.map((materialFile) => ({
        key: materialFile._id,
        ...materialFile,
      }));

      setXCount(Number(response.headers['x-count']) || 0);

      setFiles(filesData);
      setSkip(skipValue);
    },
    [api],
  );

  useEffect(() => {
    listFiles();
  }, [listFiles]);

  useEffect(() => {
    const newProps = props;
    newProps.headers = {
      authorization: `Barer ${token}`,
    };

    setDraggerProps(newProps);
  }, [props, token]);

  useEffect(() => {
    const socket =
      user &&
      socketio(baseURL, {
        query: {
          id: user.id,
        },
      });

    socket.on('materialsFile_created', (socketData: IMaterialsFileDTO) => {
      const newMaterialFile = {
        key: socketData._id,
        ...socketData,
      };

      setFiles((oldFiles) => [newMaterialFile, ...oldFiles]);
    });
  }, [baseURL, user]);

  const handleNavigate = (to: string): void => {
    history.push(to);
  };

  return (
    <Container>
      <h1>Importação de Materiais</h1>
      {token && (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Clique ou arraste o arquivo para realizar a importação
          </p>
          <p className="ant-upload-hint">
            Suporta importação de arquivos do tipo excel
          </p>
        </Dragger>
      )}

      <h3>Últimos importados:</h3>
      <Table
        columns={[
          ...columns,
          {
            title: '#',
            dataIndex: '#',
            key: '#',
            render: (_: string, record: IMaterialsFileDTO) => {
              return (
                <Actions>
                  <Button
                    title="Visualizar"
                    type="primary"
                    onClick={() =>
                      handleNavigate(`/materiais/arquivos/${record._id}`)
                    }
                  >
                    <SearchOutlined />
                  </Button>
                </Actions>
              );
            },
          },
        ]}
        dataSource={files}
        pagination={{
          total: xCount,
          onChange: (page) => listFiles((page - 1) * 10),
          pageSizeOptions: [10],
        }}
      />
    </Container>
  );
};

export default MaterialImport;
