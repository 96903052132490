import { DeleteFilled } from '@ant-design/icons';
import {
  Form,
  Input,
  AutoComplete,
  Button,
  Select,
  Table,
  Modal,
  Tag,
} from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import IMaterialDTO from '../../../dtos/IMaterialDTO';
import { useApi } from '../../../hooks/api';
import { useNotification } from '../../../hooks/notification';
import { Actions } from '../../../styles/global';
import { Container } from './styles';

const validate = {
  required: '${label} é obrigatório',
};

const { Option } = Select;

const columns = [
  {
    title: 'Código',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Un. Medida',
    dataIndex: ['measurementUnit', 'name'],
    key: 'measurementUnit',
  },
  {
    title: 'Quantidade',
    dataIndex: 'quantityTake',
    key: 'quantityTake',
  },
  {
    title: 'localização',
    dataIndex: ['location', 'description'],
    key: 'location',
  },
  {
    title: 'Gaveta',
    dataIndex: ['drawer', 'name'],
    key: 'drawer',
  },
  {
    title: 'Compartimento',
    dataIndex: 'compartment',
    key: 'compartment',
  },
];

interface IMaterialTake extends IMaterialDTO {
  quantityTake: number;
  quantityInStock: number;
}

type Option = {
  key: number;
  value: string;
  material: IMaterialDTO;
};

const NewMaterialTake: React.FC = () => {
  const { api } = useApi();
  const { openNotificationWithIcon } = useNotification();

  const [materials, setMaterials] = useState<IMaterialTake[]>([]);
  const [selectedMaterial, setSelectedMaterial] = useState<IMaterialDTO>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasMaterials, setHasMaterials] = useState(false);
  const [hasMaterialsInAutomaticDrawers, setHasMaterialsInAutomaticDrawers] =
    useState(false);
  const [options, setOptions] = useState<Option[]>();
  const [form] = Form.useForm();

  const handleSubmitTakeMaterials = async (): Promise<void> => {
    // realizar chamada para a API
    try {
      const items = materials.map(({ id, quantityTake, quantityInStock }) => ({
        material_id: id,
        quantity: quantityTake,
        quantity_in_stock: quantityInStock,
      }));

      await api.post('materials/takes', {
        items,
      });
      openNotificationWithIcon('success', {
        title: 'Retirada de material',
        content: 'Lançamento realizado com sucesso!',
      });
      setIsModalOpen(false);
      setMaterials([]);
      setOptions([]);
      setHasMaterials(false);
    } catch (error) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data.messageResponse;
      }
      openNotificationWithIcon('error', {
        title: 'Retirada de material',
        content: errorMessage || 'Erro ao processar a retirada dos materiais',
      });
    }
  };

  const handleTakeMaterials = (): void => {
    if (materials.length === 0) {
      openNotificationWithIcon('warning', {
        title: 'Retirada de material',
        content: 'Não há materiais selecionados para retirada!',
      });
      return;
    }
    const managementDrawers = materials.filter(
      (material) => material.drawer?.deviceId,
    );

    if (managementDrawers.length > 0) {
      setHasMaterialsInAutomaticDrawers(true);
    } else {
      setHasMaterialsInAutomaticDrawers(false);
    }

    setIsModalOpen(true);
  };

  const handleSubmit = (data: IMaterialTake): void => {
    const newData = {
      key: data.code,
      ...data,
    };

    setMaterials((oldvalues) => [...oldvalues, newData]);
    form.resetFields();
    setHasMaterials(true);
  };

  const handleSetMaterial = (_: string, option: Option): void => {
    const materialAlreadyAdded = materials.find(
      (material) => material.code === option.material.code,
    );
    if (materialAlreadyAdded) {
      openNotificationWithIcon('warning', {
        title: 'Retirada de material',
        content: 'Material já adicionado para retirada',
      });
      form.resetFields();
    } else if (Number(option.material.quantity) === 0) {
      openNotificationWithIcon('warning', {
        title: 'Retirada de material',
        content: 'Material sem quantidade disponível para retirada',
      });
      form.resetFields();
    } else {
      form.setFieldsValue(option.material);
    }
  };

  const getMaterial = async (value: string): Promise<void> => {
    const { data } = await api.get<IMaterialDTO[]>(
      `/materials?search=${value}&paginate=false`,
    );

    const values = data.map((material) => ({
      key: material.code,
      value: `${material.code} - ${material.description}`,
      material,
    }));

    setOptions(values);
  };

  const handleDelete = (code: number): void => {
    const arrayData = [...materials];
    const materialIndex = arrayData.findIndex((m) => m.code === code);
    arrayData.splice(materialIndex, 1);
    setMaterials(arrayData);
    setHasMaterials(arrayData.length > 0);
  };

  const header = (): JSX.Element => (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
      }}
    >
      <h1>Materiais a serem retirados</h1>
      <Button
        type="primary"
        style={{ marginLeft: '50px' }}
        onClick={() => handleTakeMaterials()}
        disabled={!hasMaterials}
      >
        Finalizar
      </Button>
    </div>
  );

  return (
    <Container>
      <h1>Retirada de Material</h1>
      <Form
        size="large"
        labelCol={{ flex: '110px' }}
        labelAlign="left"
        wrapperCol={{ flex: 1 }}
        labelWrap
        validateMessages={validate}
        onFinish={handleSubmit}
        initialValues={selectedMaterial}
        form={form}
        colon={false}
      >
        <Form.Item
          name={['id']}
          label="Id"
          wrapperCol={{ span: 8 }}
          hasFeedback
          style={{ display: 'none' }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['code']}
          label="Código"
          rules={[{ required: true }]}
          wrapperCol={{ span: 8 }}
          hasFeedback
        >
          <AutoComplete
            options={options}
            onSelect={handleSetMaterial}
            onSearch={getMaterial}
          />
        </Form.Item>

        <Form.Item
          name={['description']}
          label="Descrição"
          wrapperCol={{ span: 8 }}
          hasFeedback
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name={['quantity']}
          label="Quantidade"
          wrapperCol={{ span: 4 }}
          hasFeedback
        >
          <Input type="number" disabled />
        </Form.Item>

        <Form.Item
          name={['quantityTake']}
          label="Quantidade retirada"
          rules={[
            { required: true },
            {
              // eslint-disable-next-line consistent-return
              validator: (_, quantityTake) => {
                const quantity = form.getFieldValue('quantity');

                if (Number(quantityTake) > Number(quantity)) {
                  return Promise.reject(
                    new Error('Qtd retirada maior que a disponível'),
                  );
                }

                if (Number(quantityTake) <= 0) {
                  return Promise.reject(
                    new Error('Qtd retirada deve ser maior que 0'),
                  );
                }
                return Promise.resolve(true);
              },
            },
          ]}
          wrapperCol={{ span: 4 }}
          hasFeedback
        >
          <Input type="number" min={0} />
        </Form.Item>

        <Form.Item
          name={['quantityInStock']}
          label="Quantidade em estoque"
          rules={[
            { required: true },
            {
              // eslint-disable-next-line consistent-return
              validator: (_, quantityInStock) => {
                if (Number(quantityInStock) < 0) {
                  return Promise.reject(
                    new Error('Qtd em estoque deve ser maior que 0'),
                  );
                }
                return Promise.resolve(true);
              },
            },
          ]}
          wrapperCol={{ span: 4 }}
          hasFeedback
        >
          <Input type="number" min={0} />
        </Form.Item>

        <Form.Item
          name={['location', 'description']}
          label="Localização"
          wrapperCol={{ span: 6 }}
          hasFeedback
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name={['drawer', 'name']}
          label="Gaveta"
          wrapperCol={{ span: 2 }}
          hasFeedback
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name={['drawer', 'deviceId']}
          label="Dispositivo"
          wrapperCol={{ span: 2 }}
          hasFeedback
          style={{ display: 'none' }}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name={['compartment']}
          label="Compartimento"
          wrapperCol={{ span: 2 }}
          hasFeedback
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name={['measurementUnit', 'name']}
          label="Un. Medida"
          wrapperCol={{ span: 2 }}
          hasFeedback
        >
          <Input disabled />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Adicionar
          </Button>
        </Form.Item>
      </Form>
      <Modal
        width={1000}
        title="Deseja concluir a ação de retirada dos itens ?"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={handleSubmitTakeMaterials}
      >
        {hasMaterialsInAutomaticDrawers && (
          <Tag color="success" style={{ fontSize: '16px' }}>
            Existem alguns materiais contidos em gavetas com abertura
            automática.
          </Tag>
        )}
        <Table columns={columns} dataSource={materials} pagination={false} />
      </Modal>

      <Table
        title={() => header()}
        columns={[
          ...columns,
          {
            title: '#',
            dataIndex: '#',
            key: '#',
            render: (_: string, record: IMaterialDTO) => {
              return (
                <Actions>
                  <Button
                    danger
                    title="Excluir"
                    onClick={() => handleDelete(record.code)}
                  >
                    <DeleteFilled />
                  </Button>
                </Actions>
              );
            },
          },
        ]}
        dataSource={materials}
      />
    </Container>
  );
};

export default NewMaterialTake;
