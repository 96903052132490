/* eslint-disable no-underscore-dangle */
import { Input, Table, Button } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import { useCallback, useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Actions, Container } from './styles';
import { ActionsType, transform } from '../../../helper/action-transform';
import { useApi } from '../../../hooks/api';
import ILogDTO from '../../../dtos/ILogDTO';
import IUserDTO from '../../../dtos/IUserDTO';

const columns = [
  {
    title: 'Ação',
    dataIndex: 'action',
    key: 'action',
    render: (action: ActionsType) => transform(action),
  },
  {
    title: 'Usuário',
    dataIndex: ['user', 'name'],
    key: 'userName',
  },
  {
    title: 'Matrícula',
    dataIndex: ['user', 'enrollment'],
    key: 'userEnrollment',
  },
  {
    title: 'Tipo de Usuário',
    dataIndex: ['user', 'type'],
    key: 'userType',
    render: (type: IUserDTO['type']) =>
      type === 'ADMIN' ? 'Administrador' : 'Mecânico',
  },
  {
    title: 'Data',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => format(parseISO(date), 'dd/MM/yyyy HH:mm:ss'),
  },
];

const Logs: React.FC = () => {
  const [logs, setLogs] = useState<ILogDTO[]>([]);
  const [xCount, setXCount] = useState(0);
  const [search, setSearch] = useState('');

  const history = useHistory();

  const { api } = useApi();

  const listLogs = useCallback(
    async (skip = 0): Promise<void> => {
      let queryParams = `skip=${skip}`;
      if (search) {
        queryParams += `&enrollment=${search}`;
      }

      const response = await api.get<ILogDTO[]>(`/users/logs?${queryParams}`);

      const data = response.data.map((log) => ({
        key: log._id,
        ...log,
      }));

      setXCount(Number(response.headers['x-count']) || 0);
      setLogs(data);
    },
    [api, search],
  );

  const handleNavigate = (to: string): void => {
    history.push(to);
  };

  useEffect(() => {
    listLogs();
  }, [listLogs]);

  return (
    <Container>
      <h1>Log de usuários</h1>
      <Input.Search
        placeholder="Buscar por matricula"
        onSearch={(value) => setSearch(value)}
        style={{ width: 600, marginBottom: 20 }}
      />
      <Table
        columns={[
          ...columns,
          {
            title: '#',
            dataIndex: '#',
            key: '#',
            render: (_: string, record) => {
              return (
                <Actions>
                  <Button
                    title="Visualizar"
                    type="primary"
                    onClick={() => handleNavigate(`/logs/${record._id}`)}
                  >
                    <SearchOutlined />
                  </Button>
                </Actions>
              );
            },
          },
        ]}
        dataSource={logs}
        pagination={{
          total: xCount,
          onChange: (page) => listLogs((page - 1) * 10),
          pageSizeOptions: [10],
        }}
      />
    </Container>
  );
};

export default Logs;
