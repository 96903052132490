const actions = {
  UserSession: 'Se logou na plataforma',
  UpdateAccessPoint: 'Atualizou um ponto de acesso',
  OpenAccessPointToMaintenance: 'Abriu um ponto de acesso para manutenção',
  CreateDevice: 'Criou um novo dispositivo',
  UpdateDevice: 'Atualizou um dispositivo',
  OpenDevice: 'Abriu um dispositivo',
  CreateCostCenter: 'Criou um centro de custo',
  UpdateCostCenter: 'Atualizou um centro de custo',
  CreateDeposit: 'Criou um depósito',
  UpdateDeposit: 'Realizou a atualização de um depósito',
  CreateLocation: 'Criou uma nova localização',
  UpdateLocation: 'Atualizou uma localização',
  CreateMaterial: 'Criou um novo material',
  UpdateMaterial: 'Atualizou um material',
  ImportMaterial: 'Realizou uma importação de materiais',
  CreateMaterialType: 'Criou um tipo de material',
  UpdateMaterialType: 'Atualizou um tipo de material',
  CreateMeasurementUnit: 'Criou uma nova unidade de medida',
  UpdateMeasurementUnit: 'Atualizou uma unidade de medida',
  TakeMaterials: 'Efetuou uma retirada de materiais',
  CreateUser: 'Criou um novo usuário',
  UpdateUser: 'Alterou um usuário',
  DeleteUser: 'Deletou um usuário',
};

export type ActionsType = 'UserSession';

export function transform(action: ActionsType): string {
  return actions[action];
}
