import { Button, Input, Form, Alert, Select, Divider } from 'antd';

import { useEffect, useState } from 'react';
import { Container, Content, Background } from './styles';

import siginInBackgroundImg from '../../assets/background.svg';

// import logo from '../../assets/logo.svg';
import { useAuth } from '../../hooks/auth';

interface ISignInData {
  enrollment: string;
  password: string;
}

const SignIn: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [authType, setAuthType] = useState('MECHANICAL');
  const [errorMessage, setErrorMessage] = useState(
    'Usuário ou senha incorretos',
  );
  const { signInAdmin, signInMechanical } = useAuth();

  useEffect(() => {
    authType === 'MECHANICAL'
      ? setErrorMessage('Matrícula incorreta')
      : setErrorMessage('Usuário ou senha incorretos');
  }, [authType]);

  const handleSignIn = async ({
    enrollment,
    password = '',
  }: ISignInData): Promise<void> => {
    setHasError(false);
    try {
      if (authType === 'MECHANICAL') {
        await signInMechanical({
          enrollment,
        });
      } else {
        await signInAdmin({
          enrollment,
          password,
        });
      }
    } catch (error) {
      setHasError(true);
    }
  };

  return (
    <Container>
      <Background>
        <img src={siginInBackgroundImg} alt="Log App" />
      </Background>
      <Content>
        {/* <img src={logo} alt="SeuDoctor" /> */}
        <Form onFinish={handleSignIn}>
          <h2>Entrar no sistema</h2>
          {hasError && (
            <Alert message={errorMessage} type="warning" showIcon closable />
          )}

          <Form.Item
            name="access"
            label="Acessar como:"
            initialValue="MECHANICAL"
          >
            <Select onChange={(value) => setAuthType(value)}>
              <Select.Option value="MECHANICAL">Mecânico</Select.Option>
              <Select.Option value="ADMIN">Administrador</Select.Option>
            </Select>
          </Form.Item>

          <Divider style={{ marginTop: '10px' }} />

          <Form.Item
            label="Matricula"
            name="enrollment"
            rules={[
              {
                required: true,
                message: 'Por favor informe sua matrícula',
              },
            ]}
          >
            <Input />
          </Form.Item>

          {authType === 'ADMIN' && (
            <Form.Item
              label="Senha"
              name="password"
              rules={[
                { required: true, message: 'For favor informe sua senha' },
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}

          <Button type="primary" htmlType="submit">
            Entrar
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default SignIn;
