/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
import { Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container } from './styles';
import IMaterialsImportsDTO from '../../../dtos/IMaterialsImportsDTO';
import { useApi } from '../../../hooks/api';

const columns = [
  {
    title: 'Código',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Quantidade',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Processado?',
    dataIndex: 'processed',
    key: 'processed',
    render: (processed: boolean) => (processed ? 'sim' : 'não'),
  },
];

const MaterialImportsList: React.FC = () => {
  const { api } = useApi();
  const { id } = useParams<{ id: string }>();

  const [data, setData] = useState<IMaterialsImportsDTO[]>([]);
  const [xCount, setXCount] = useState(0);

  const listMaterialsImports = useCallback(
    async (skipValue = 0): Promise<void> => {
      const response = await api.get<IMaterialsImportsDTO[]>(
        `/materials/files/${id}/imports?skip=${skipValue}`,
      );

      const filesImportsData = response.data.map((dt) => ({
        key: dt._id,
        ...dt,
      }));

      setXCount(Number(response.headers['x-count']) || 0);

      setData(filesImportsData);
    },
    [api, id],
  );

  useEffect(() => {
    listMaterialsImports();
  }, [listMaterialsImports]);

  return (
    <Container>
      <h1>Materiais importados</h1>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          total: xCount,
          onChange: (page) => listMaterialsImports((page - 1) * 10),
          pageSizeOptions: [10],
        }}
      />
    </Container>
  );
};

export default MaterialImportsList;
