import { ApiProvider } from './api';
import { AuthProvider } from './auth';
import { NotificationProvider } from './notification';

type Props = {
  children: React.ReactElement;
};

const AppProvider: React.FC<Props> = ({ children }) => (
  <ApiProvider>
    <AuthProvider>
      <NotificationProvider>{children}</NotificationProvider>
    </AuthProvider>
  </ApiProvider>
);

export default AppProvider;
