import { Form, Input, Button } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IMaterialTypeDTO from '../../../../dtos/IMaterialTypeDTO';
import { useApi } from '../../../../hooks/api';
import { useNotification } from '../../../../hooks/notification';
import { Container } from './styles';

const validate = {
  required: '${label} é obrigatório',
};

const EditMaterialTypes: React.FC = () => {
  const { api } = useApi();
  const { openNotificationWithIcon } = useNotification();
  const [materialType, setMaterialType] = useState<IMaterialTypeDTO>();
  const [form] = Form.useForm();

  const { id } = useParams<{ id: string }>();

  const handleSubmit = async (data: IMaterialTypeDTO): Promise<void> => {
    try {
      await api.put(`materialTypes/${id}`, data);
      openNotificationWithIcon('success', {
        title: 'Tipo de material',
        content: 'Tipo de material alterado com sucesso!',
      });
    } catch (error) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data.messageResponse;
      }
      openNotificationWithIcon('error', {
        title: 'Tipo de material',
        content: errorMessage || 'Erro ao alterar o tipo de material!',
      });
    }
  };

  const getMaterialType = useCallback(async (): Promise<void> => {
    const response = await api.get(`materialTypes/${id}`);
    setMaterialType(response.data);
  }, [api, id]);

  useEffect(() => {
    getMaterialType();
  }, [getMaterialType]);

  return (
    <Container>
      <h1>Alterar Tipo de Material</h1>
      {materialType && (
        <Form
          size="large"
          labelCol={{ flex: '110px' }}
          labelAlign="left"
          wrapperCol={{ flex: 1 }}
          labelWrap
          colon={false}
          initialValues={materialType}
          validateMessages={validate}
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            name={['name']}
            label="Nome"
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form>
      )}
    </Container>
  );
};

export default EditMaterialTypes;
