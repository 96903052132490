import { SearchOutlined } from '@ant-design/icons';
import { format, parseISO } from 'date-fns';
import { Button, Input, Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApi } from '../../../hooks/api';
import { Actions, Container } from './styles';
import IMaterialTakeDTO from '../../../dtos/IMaterialTakeDTO';

const columns = [
  {
    title: 'Usuário',
    dataIndex: ['user', 'name'],
    key: 'user',
  },
  {
    title: 'Matrícula',
    dataIndex: ['user', 'enrollment'],
    key: 'user',
  },
  {
    title: 'Data',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date: string) => format(parseISO(date), 'dd/MM/yyyy HH:mm:ss'),
  },
];

const ListMaterialTake: React.FC = () => {
  const { api } = useApi();
  const history = useHistory();
  const [materialsTaken, setMaterialsTaken] = useState<IMaterialTakeDTO[]>();
  const [search, setSearch] = useState('');
  const [xCount, setXCount] = useState(0);

  const listMaterialsTaken = useCallback(
    async (skip = 0) => {
      const response = await api.get<IMaterialTakeDTO[]>(
        `materials/takes?search=${search}&skip=${skip}&paginate=true`,
      );

      const data = response.data.map((materialTaken) => ({
        key: materialTaken.id,
        ...materialTaken,
      }));

      setXCount(Number(response.headers['x-count']) || 0);
      setMaterialsTaken(data);
    },
    [api, search],
  );

  useEffect(() => {
    listMaterialsTaken();
  }, [listMaterialsTaken]);

  const handleNavigate = (to: string, data: IMaterialTakeDTO): void => {
    history.push(to, data);
  };

  return (
    <Container>
      <h1>Retiradas</h1>
      <Input.Search
        placeholder="Buscar por matrícula"
        onSearch={(value) => setSearch(value)}
        style={{ width: 600, marginBottom: 20 }}
      />
      <h4>Total {xCount}</h4>

      <Table
        columns={[
          ...columns,
          {
            title: '#',
            dataIndex: '#',
            key: '#',
            render: (_: string, record: IMaterialTakeDTO) => {
              return (
                <Actions>
                  <Button
                    title="Visualizar"
                    type="primary"
                    onClick={() =>
                      handleNavigate(`/retiradas/${record.id}`, record)
                    }
                  >
                    <SearchOutlined />
                  </Button>
                </Actions>
              );
            },
          },
        ]}
        dataSource={materialsTaken}
        pagination={{
          total: xCount,
          onChange: (page) => listMaterialsTaken((page - 1) * 10),
          pageSizeOptions: [10],
        }}
      />
    </Container>
  );
};

export default ListMaterialTake;
