import { Form, Input, Button } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IMeasurementUnitDTO from '../../../../dtos/IMeasurementUnitDTO';
import { useApi } from '../../../../hooks/api';
import { useNotification } from '../../../../hooks/notification';
import { Container } from './styles';

const validate = {
  required: '${label} é obrigatório',
};

const EditMeasurementUnit: React.FC = () => {
  const { api } = useApi();
  const { openNotificationWithIcon } = useNotification();
  const [measurementUnit, setMeasurementUnit] = useState<IMeasurementUnitDTO>();
  const [form] = Form.useForm();

  const { id } = useParams<{ id: string }>();

  const handleSubmit = async (data: IMeasurementUnitDTO): Promise<void> => {
    try {
      await api.put(`measurementUnits/${id}`, data);

      openNotificationWithIcon('success', {
        title: 'Unidade de medida',
        content: 'Unidade de medida alterada com sucesso!',
      });
    } catch (error) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data.messageResponse;
      }
      openNotificationWithIcon('error', {
        title: 'Unidade de medida',
        content: errorMessage || 'Erro ao alterar a unidade de medida!',
      });
    }
  };

  const getMeasurementUnit = useCallback(async (): Promise<void> => {
    const response = await api.get(`measurementUnits/${id}`);
    setMeasurementUnit(response.data);
  }, [api, id]);

  useEffect(() => {
    getMeasurementUnit();
  }, [getMeasurementUnit]);

  return (
    <Container>
      <h1>Alterar Unidade de Medida</h1>
      {measurementUnit && (
        <Form
          size="large"
          labelCol={{ flex: '110px' }}
          labelAlign="left"
          wrapperCol={{ flex: 1 }}
          labelWrap
          colon={false}
          initialValues={measurementUnit}
          validateMessages={validate}
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            name={['name']}
            label="Nome"
            rules={[{ required: true }]}
            wrapperCol={{ span: 12 }}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={['description']}
            label="Descrição"
            rules={[{ required: true }]}
            wrapperCol={{ span: 12 }}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form>
      )}
    </Container>
  );
};

export default EditMeasurementUnit;
