import { notification } from 'antd';
import { createContext, useContext } from 'react';

interface NotificationData {
  title: string;
  content: string;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface NotificationContextData {
  openNotificationWithIcon(
    type: NotificationType,
    data: NotificationData,
  ): void;
}

type Props = {
  children: React.ReactElement;
};

const NotificationContext = createContext<NotificationContextData>(
  {} as NotificationContextData,
);

const NotificationProvider: React.FC<Props> = ({ children }) => {
  const [notificationApi, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (
    type: NotificationType,
    data: NotificationData,
  ): void => {
    notificationApi[type]({
      message: data.title,
      description: data.content,
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        openNotificationWithIcon,
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

function useNotification(): NotificationContextData {
  const context = useContext(NotificationContext);

  return context;
}

export { NotificationProvider, useNotification };
