import { createContext, useCallback, useState, useContext } from 'react';

import { useApi } from './api';

type User = {
  id: string;
  name: string;
  type: 'ADMIN' | 'MECHANICAL';
};

interface AuthState {
  token: string;
  user: User;
}

interface AdminCredentials {
  enrollment: string;
  password: string;
}

interface MechanicalCredentials {
  enrollment: string;
}

interface AuthContextData {
  user: User;
  token: string;
  signInAdmin(credentials: AdminCredentials): Promise<void>;
  signInMechanical(credentials: MechanicalCredentials): Promise<void>;
  signOut(): void;
}

type Props = {
  children: React.ReactElement;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const { api } = useApi();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@inout:token');
    const user = localStorage.getItem('@inout:user');

    if (token && user) {
      api.defaults.headers.authorization = `Barer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signInAdmin = useCallback(
    async ({ enrollment, password }: AdminCredentials) => {
      const response = await api.post('/sessions', {
        enrollment,
        password,
      });
      const { token, user } = response.data;
      localStorage.setItem('@inout:token', token);
      localStorage.setItem('@inout:user', JSON.stringify(user));
      api.defaults.headers.authorization = `Barer ${token}`;
      setData({ token, user });
    },
    [api],
  );

  const signInMechanical = useCallback(
    async ({ enrollment }: MechanicalCredentials) => {
      const response = await api.post('/sessions', {
        enrollment,
      });
      const { token, user } = response.data;
      localStorage.setItem('@inout:token', token);
      localStorage.setItem('@inout:user', JSON.stringify(user));
      api.defaults.headers.authorization = `Barer ${token}`;
      setData({ token, user });
    },
    [api],
  );

  const signOut = useCallback(async () => {
    localStorage.removeItem('@inout:token');
    localStorage.removeItem('@inout:user');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signInAdmin,
        signInMechanical,
        signOut,
        token: data.token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
