import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 700px;

  form {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 320px;
    padding: 0 20px;
    text-align: center;
    > h1 {
      margin-bottom: 24px;
    }

    > input {
      height: 40px;
      margin-bottom: 12px;
      & + input {
        margin-bottom: 5px;
      }
    }

    > button {
      height: 35px;
      font-weight: bold;
      font-size: 16px;
    }
  }
`;

const appearFromLeft = keyframes`
  from{
    opacity: 0;
    transform: translateX(-50px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  width: 500px;
  height: 400px;
  border-radius: 10px;
  border: 2px solid #e5e5e5;
  box-shadow: 0px 20px 40px rgba(238, 77, 71, 0.1);
  animation: ${appearFromLeft} 1s;
  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;
    h1 {
      margin-bottom: 24px;
    }
    a {
      color: #6a6161;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.2, '#6A6161')};
      }
    }
    > div {
      margin-bottom: 10px;
    }
  }
`;

export const Background = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 60px;
  align-items: center;
  > img {
    width: 80%;
  }
`;
