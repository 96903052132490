import { Descriptions, Table, Tag, Tooltip } from 'antd';
import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import IItemTakeDTO from '../../../dtos/IItemTakeDTO';
import IMaterialTakeDTO from '../../../dtos/IMaterialTakeDTO';
import { useApi } from '../../../hooks/api';
import { Container } from './styles';

const columns = [
  {
    title: 'Código',
    dataIndex: ['material', 'code'],
    key: 'code',
  },
  {
    title: 'Material',
    dataIndex: ['material', 'description'],
    key: 'description',
  },
  {
    title: 'Quantidade',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Quantidade informada',
    dataIndex: 'quantity_in_stock',
    key: 'quantity_in_stock',
    render: (_: string, record: IItemTakeDTO) => {
      const color =
        record.previous_quantity - record.quantity ===
        Number(record.quantity_in_stock)
          ? 'success'
          : 'error';

      const title =
        color === 'success'
          ? 'A quantidade informada pelo usuário está correta!'
          : 'A quantidade informada pelo usuário está incorreta!';

      return (
        <Tooltip title={title}>
          <Tag color={color}>{record.quantity_in_stock}</Tag>
        </Tooltip>
      );
    },
  },
  {
    title: 'Quantidade Material',
    dataIndex: 'previous_quantity',
    key: 'previous_quantity',
  },
  {
    title: 'Quantidade restante em estoque',
    dataIndex: '#',
    key: '#',
    render: (_: string, record: IItemTakeDTO) =>
      record.previous_quantity - record.quantity,
  },
];

const ViewMaterialsTakenItems: React.FC = () => {
  const [xCount, setXCount] = useState(0);
  const [itemsTaken, setItemsTaken] = useState<IItemTakeDTO[]>();
  const { api } = useApi();
  const { state } = useLocation<IMaterialTakeDTO>();

  const date = format(parseISO(state.created_at), 'dd/MM/yyyy HH:mm:ss');

  const getTakenMaterials = useCallback(
    async (skip = 0): Promise<void> => {
      const response = await api.get<IItemTakeDTO[]>(
        `/materials/takes/${state.id}/items?skip=${skip}&paginate=true`,
      );

      const data = response.data.map((itemTake) => ({
        key: itemTake.id,
        ...itemTake,
      }));

      setXCount(Number(response.headers['x-count']) || 0);
      setItemsTaken(data);
    },
    [api, state.id],
  );

  useEffect(() => {
    getTakenMaterials();
  }, [getTakenMaterials]);

  return (
    <Container>
      <h2>Retirada de material</h2>
      <Descriptions title="Informações da retirada">
        <Descriptions.Item label="Usuário">{state.user.name}</Descriptions.Item>
        <Descriptions.Item label="Data">{date}</Descriptions.Item>
      </Descriptions>
      <h3>Materiais retirados</h3>
      <Table
        columns={columns}
        dataSource={itemsTaken}
        pagination={{
          total: xCount,
          onChange: (page) => getTakenMaterials((page - 1) * 10),
          pageSizeOptions: [10],
        }}
      />
    </Container>
  );
};

export default ViewMaterialsTakenItems;
