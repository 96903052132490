import axios, { AxiosInstance } from 'axios';
import { createContext, useContext } from 'react';

interface ExtendedWindow extends Window {
  _env_?: any;
}

type Props = {
  children: React.ReactElement;
};

interface ApiContextData {
  api: AxiosInstance;
  baseURL: any;
}

const ApiContext = createContext<ApiContextData>({} as ApiContextData);

const ApiProvider: React.FC<Props> = ({ children }) => {
  const { _env_ }: ExtendedWindow = window;

  const baseURL = _env_?.REACT_APP_API_URL || 'http://localhost:3333';

  const api = axios.create({
    baseURL,
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error): Promise<void> => {
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.removeItem('@inout:token');
        localStorage.removeItem('@inout:user');
        document.location.href = '/';
        return Promise.resolve();
      }
      return Promise.reject(error);
    },
  );

  return (
    <ApiContext.Provider value={{ api, baseURL }}>
      {children}
    </ApiContext.Provider>
  );
};

function useApi(): ApiContextData {
  const context = useContext(ApiContext);

  return context;
}

export { ApiProvider, useApi };
