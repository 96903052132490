import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes';
// TODO create hooks
import AppProvider from './hooks';
import { GlobalStyle } from './styles/global';

const App: React.FC = () => {
  return (
    <Router>
      <AppProvider>
        <Routes />
      </AppProvider>
      <GlobalStyle />
    </Router>
  );
};

export default App;
