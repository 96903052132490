/* eslint-disable no-underscore-dangle */
import useSWR from 'swr';
import { useApi } from './api';

export function useFetch<Data = any, Error = any>(url: string): any {
  const { api } = useApi();
  const { data, error } = useSWR<Data, Error>(
    url,
    async (urlRequest) => {
      const response = await api.get(urlRequest);

      return response.data;
    },
    {
      refreshInterval: 1000,
    },
  );

  return { data, error };
}
