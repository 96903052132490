/* eslint-disable @typescript-eslint/no-explicit-any */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Button, Radio, Space, Select } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import IAccessPointDTO from '../../../dtos/IAccessPointDTO';
import ILocationDTO from '../../../dtos/ILocationDTO';
import { useApi } from '../../../hooks/api';
import { useNotification } from '../../../hooks/notification';
import { Container } from './styles';

const validate = {
  required: '${label} é obrigatório',
};

const NewLocation: React.FC = () => {
  const { api } = useApi();
  const { openNotificationWithIcon } = useNotification();
  const [hasDrawer, setHasDrawer] = useState();
  const [accessPoints, setAccessPoints] = useState<IAccessPointDTO[]>([]);

  const [form] = Form.useForm();

  const listAccessPoints = useCallback(async (): Promise<void> => {
    const response = await api.get<IAccessPointDTO[]>('accessPoints');

    setAccessPoints(response.data);
  }, [api]);

  const handleAccessPointSelected = (name: number, value: any): void => {
    if (value) {
      const accessPointSelected = accessPoints.find((a) => a.id === value);

      if (!accessPointSelected || !accessPointSelected.num_porta) {
        openNotificationWithIcon('error', {
          title: 'Localização',
          content: 'Fechadura com cadastro incompleto!',
        });
        return;
      }

      form.setFieldValue(
        ['drawers', name, 'port'],
        accessPointSelected?.num_porta,
      );
    } else {
      form.setFieldValue(['drawers', name, 'port'], 0);
    }
  };

  useEffect(() => {
    listAccessPoints();
  }, [listAccessPoints]);

  const handleSubmit = async (data: ILocationDTO): Promise<void> => {
    try {
      await api.post('locations', data);
      openNotificationWithIcon('success', {
        title: 'Localização',
        content: 'Localização cadastrada com sucesso!',
      });
      form.resetFields();
    } catch (error) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data.messageResponse;
      }
      openNotificationWithIcon('error', {
        title: 'Localização',
        content: errorMessage || 'Erro ao cadastrar a localização!',
      });
    }
  };

  return (
    <Container>
      <h1>Cadastro de Localizações de Materiais</h1>
      <Form
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        validateMessages={validate}
        size="large"
        labelCol={{ flex: '115px' }}
        labelAlign="left"
        wrapperCol={{ flex: 1 }}
        labelWrap
        colon={false}
      >
        <Form.Item
          name="description"
          label="Descrição"
          rules={[{ required: true }]}
          wrapperCol={{ span: 12 }}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="hasDrawer"
          label="Possui gavetas ?"
          rules={[{ required: true }]}
          hasFeedback
        >
          <Radio.Group onChange={(e) => setHasDrawer(e.target.value)}>
            <Radio value>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Radio.Group>
        </Form.Item>
        {hasDrawer && (
          <Form.Item>
            <Form.List name="drawers">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...field }) => (
                    <Space key={key} align="baseline">
                      <Form.Item
                        {...field}
                        label="Descrição"
                        name={[name, 'name']}
                        rules={[{ required: true }]}
                        hasFeedback
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="Compartimentos"
                        name={[name, 'compartments']}
                        initialValue={0}
                      >
                        <Input type="number" />
                      </Form.Item>

                      <Form.Item noStyle shouldUpdate>
                        <Form.Item
                          {...field}
                          label="Fechadura"
                          name={[name, 'deviceId']}
                          hasFeedback
                        >
                          <Select
                            style={{ width: '250px', marginRight: '30px' }}
                            onChange={(value) =>
                              handleAccessPointSelected(name, value)
                            }
                          >
                            <Select.Option value={undefined} key={undefined}>
                              Sem fechadura
                            </Select.Option>
                            {accessPoints.map((accessPoint) => (
                              <Select.Option
                                value={accessPoint.id}
                                key={accessPoint.id}
                              >
                                {accessPoint.nome_porta}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Form.Item>

                      <Form.Item
                        {...field}
                        label="Porta"
                        name={[name, 'port']}
                        style={{ display: 'none' }}
                      >
                        <Input type="number" />
                      </Form.Item>

                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}

                  <Form.Item wrapperCol={{ span: 12 }}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Adicionar gaveta
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default NewLocation;
