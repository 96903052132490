import { Layout, Menu, MenuProps } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AppstoreOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PlusCircleFilled,
  OrderedListOutlined,
  UploadOutlined,
  PoweroffOutlined,
  UserOutlined,
  ToolOutlined,
  DollarCircleOutlined,
  FolderOpenOutlined,
  TabletOutlined,
  ExportOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';

import { useState } from 'react';
import { Logo, Header } from './styles';

// TODO adicionar logo da aplicação
import logo from '../../assets/logo-inout.png';
import logoSmall from '../../assets/logo-small.png';

import { SiteLayout } from '../../styles/global';

import { useAuth } from '../../hooks/auth';

const { Sider } = Layout;
// const { SubMenu } = Menu;

type Props = {
  children: React.ReactElement;
};

const rootSubmenuKeys = [
  'dash',
  'materialTake',
  'users',
  'materials',
  'costCenters',
  'deposits',
  'devices',
  'locations',
];

const SiderComponent: React.FC<Props> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState(['sub1']);
  const [selectedLogo, setSelectedLogo] = useState(logo);

  const { signOut, user } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();

  const items = [
    {
      key: 'dash',
      icon: <AppstoreOutlined />,
      label: 'Dashboard',
      permissions: ['ADMIN'],
      onClick: () => {
        history.push('/dashboard');
      },
      className:
        pathname === '/dashboard'
          ? 'ant-menu-item ant-menu-item-selected'
          : 'ant-menu-item',
    },
    {
      key: 'materialTakeAdmin',
      icon: <ExportOutlined />,
      label: 'Retirada de Material',
      permissions: ['ADMIN'],
      children: [
        {
          key: 'materialTake-list',
          label: 'Retiradas',
          icon: <OrderedListOutlined />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/retiradas');
          },
        },
        {
          key: 'materialtake-new',
          label: 'Novo',
          icon: <PlusCircleFilled />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/retiradas/novo');
          },
        },
      ],
    },
    {
      key: 'materialTakeMechanical',
      icon: <ExportOutlined />,
      label: 'Retirada de Material',
      permissions: ['MECHANICAL'],
      children: [
        {
          key: 'materialtake-new',
          label: 'Novo',
          icon: <PlusCircleFilled />,
          permissions: ['MECHANICAL'],
          onClick: () => {
            history.push('/retiradas/novo');
          },
        },
      ],
    },
    {
      key: 'users',
      icon: <UserOutlined />,
      label: 'Usuários',
      permissions: ['ADMIN'],
      children: [
        {
          key: 'users-list',
          label: 'Usuários',
          icon: <OrderedListOutlined />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/usuarios');
          },
        },
        {
          key: 'users-new',
          label: 'Novo',
          icon: <PlusCircleFilled />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/usuarios/novo');
          },
        },
      ],
    },
    {
      key: 'materials',
      icon: <ToolOutlined />,
      label: 'Materiais',
      permissions: ['ADMIN'],
      children: [
        {
          key: 'materials-list',
          label: 'Materiais',
          icon: <OrderedListOutlined />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/materiais');
          },
        },
        {
          key: 'materials-new',
          label: 'Novo',
          icon: <PlusCircleFilled />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/materiais/novo');
          },
        },
        {
          key: 'materials-import',
          label: 'Importar',
          icon: <UploadOutlined />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/materiais/importar');
          },
        },
        {
          key: 'measurementUnits',
          label: 'Un. de medida',
          icon: <OrderedListOutlined />,
          permissions: ['ADMIN'],
          children: [
            {
              key: 'measurementUnits-list',
              label: 'Un. de medida',
              icon: <OrderedListOutlined />,
              permissions: ['ADMIN'],
              onClick: () => {
                history.push('/unidades-medida');
              },
            },
            {
              key: 'measurementUnits-new',
              label: 'Novo',
              icon: <PlusCircleFilled />,
              permissions: ['ADMIN'],
              onClick: () => {
                history.push('/unidades-medida/novo');
              },
            },
          ],
        },
        {
          key: 'types',
          label: 'Tipos',
          icon: <OrderedListOutlined />,
          permissions: ['ADMIN'],
          children: [
            {
              key: 'types-list',
              label: 'Tipos',
              icon: <OrderedListOutlined />,
              permissions: ['ADMIN'],
              onClick: () => {
                history.push('/tipos-material');
              },
            },
            {
              key: 'types-new',
              label: 'Novo',
              icon: <PlusCircleFilled />,
              permissions: ['ADMIN'],
              onClick: () => {
                history.push('/tipos-material/novo');
              },
            },
          ],
        },
      ],
    },
    {
      key: 'costCenters',
      label: 'Centros de Custo',
      permissions: ['ADMIN'],
      icon: <DollarCircleOutlined />,
      children: [
        {
          key: 'costCenters-list',
          label: 'Centros de Custo',
          icon: <OrderedListOutlined />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/centros-custo');
          },
        },
        {
          key: 'costCenters-new',
          label: 'Novo',
          icon: <PlusCircleFilled />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/centros-custo/novo');
          },
        },
      ],
    },
    {
      key: 'deposits',
      label: 'Depósitos',
      permissions: ['ADMIN'],
      icon: <OrderedListOutlined />,
      children: [
        {
          key: 'deposits-list',
          label: 'Depósitos',
          icon: <OrderedListOutlined />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/depositos');
          },
        },
        {
          key: 'deposits-new',
          label: 'Novo',
          icon: <PlusCircleFilled />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/depositos/novo');
          },
        },
      ],
    },
    {
      key: 'devices',
      label: 'Dispositivos',
      permissions: ['ADMIN'],
      icon: <TabletOutlined />,
      children: [
        {
          key: 'devices-list',
          label: 'Dispositivos',
          icon: <OrderedListOutlined />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/dispositivos');
          },
        },
        {
          key: 'devices-new',
          label: 'Novo',
          icon: <PlusCircleFilled />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/dispositivos/novo');
          },
        },
      ],
    },
    {
      key: 'locations',
      label: 'Localizações',
      permissions: ['ADMIN'],
      icon: <FolderOpenOutlined />,
      children: [
        {
          key: 'locations-list',
          label: 'Localizações',
          icon: <OrderedListOutlined />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/localizacoes');
          },
        },
        {
          key: 'locations-new',
          label: 'Novo',
          icon: <PlusCircleFilled />,
          permissions: ['ADMIN'],
          onClick: () => {
            history.push('/localizacoes/novo');
          },
        },
      ],
    },
    {
      key: 'logs',
      label: 'Log de usuário',
      permissions: ['ADMIN'],
      icon: <FieldTimeOutlined />,
      onClick: () => {
        history.push('/logs');
      },
    },
  ];

  const onCollapse = (): void => {
    const isCollapsed = !collapsed;

    setSelectedLogo(() => (isCollapsed ? logoSmall : logo));
    setCollapsed(isCollapsed);
  };

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Logo>
          <img alt="InOut" src={selectedLogo} />
        </Logo>
        <Menu
          mode="inline"
          items={items.filter((item) => item?.permissions?.includes(user.type))}
          theme="dark"
          onOpenChange={onOpenChange}
          openKeys={openKeys}
        />
      </Sider>
      <SiteLayout>
        <Header>
          {collapsed ? (
            <MenuUnfoldOutlined onClick={onCollapse} />
          ) : (
            <MenuFoldOutlined onClick={onCollapse} />
          )}
          <div>
            <b>{user.name}</b>
            <PoweroffOutlined onClick={signOut} />
          </div>
        </Header>
        {children}
      </SiteLayout>
    </>
  );
};

export default SiderComponent;
