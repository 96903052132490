import styled from 'styled-components';

import { Layout, Card } from 'antd';

const { Content: ContentLayout } = Layout;

interface CardProps {
  backgroundColor?: string;
}

export const Container = styled(ContentLayout)`
  > div + div {
    margin-top: 20px;
  }
`;

export const CardLayout = styled(Card).attrs<CardProps>((props) => ({
  headStyle: {
    backgroundColor: props.backgroundColor ? props.backgroundColor : '#dc3545',
    borderRadius: '10px 10px 0 0',
    color: '#fff',
  },
}))<CardProps>`
  border-radius: 10px;
  color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#dc3545'};
  span {
    font-size: 32px;
  }
`;
