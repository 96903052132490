import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IDepositDTO from '../../../dtos/IDepositDTO';
import { useApi } from '../../../hooks/api';
import { Actions, Container } from './styles';

const columns = [
  {
    title: 'Código',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
  },
];

const ListDeposits: React.FC = () => {
  const { api } = useApi();
  const [deposits, setDeposits] = useState<IDepositDTO[]>();
  const [search, setSearch] = useState('');
  const [xCount, setXCount] = useState(0);

  const history = useHistory();

  const listDeposits = useCallback(
    async (skip = 0): Promise<void> => {
      const response = await api.get<IDepositDTO[]>(
        `deposits?search=${search}&skip=${skip}`,
      );

      const data = response.data.map((deposit) => ({
        key: deposit.id,
        ...deposit,
      }));

      setXCount(Number(response.headers['x-count']) || 0);
      setDeposits(data);
    },
    [api, search],
  );

  useEffect(() => {
    listDeposits();
  }, [listDeposits]);

  const handleNavigate = (to: string): void => {
    history.push(to);
  };

  return (
    <Container>
      <h1>Depositos</h1>
      <Input.Search
        placeholder="Buscar por código"
        onSearch={(value) => setSearch(value)}
        style={{ width: 400, marginBottom: 20 }}
      />
      <Table
        columns={[
          ...columns,
          {
            title: '#',
            dataIndex: '#',
            key: '#',
            render: (_: string, record: IDepositDTO) => {
              return (
                <Actions>
                  <Button
                    title="Editar"
                    type="primary"
                    onClick={() => handleNavigate(`/depositos/${record.id}`)}
                  >
                    <EditOutlined />
                  </Button>
                  {/* <Button danger title="Excluir">
                    <DeleteFilled />
                  </Button> */}
                </Actions>
              );
            },
          },
        ]}
        dataSource={deposits}
        pagination={{
          total: xCount,
          onChange: (page) => listDeposits((page - 1) * 10),
          pageSizeOptions: [10],
        }}
      />
    </Container>
  );
};

export default ListDeposits;
