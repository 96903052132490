import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import DefaultLayout from '../pages/_layouts/default';
import AuthLayout from '../pages/_layouts/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  permissions?: string[];
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  permissions,
  ...rest
}) => {
  const { user } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        const hasPermission = isPrivate
          ? permissions?.includes(user?.type)
          : !user;

        const Layout = hasPermission && user ? DefaultLayout : AuthLayout;

        return hasPermission ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              // eslint-disable-next-line no-nested-ternary
              pathname: isPrivate
                ? '/'
                : user.type === 'ADMIN'
                ? '/dashboard'
                : '/retiradas/novo',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
