import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';

import Dashboard from '../pages/Dashboard';

import MaterialTake from '../pages/MaterialTake/new';

import Users from '../pages/Users/list';
import NewUser from '../pages/Users/new';

import CostCenters from '../pages/CostCenters/list';
import NewCostCenter from '../pages/CostCenters/new';

import Deposits from '../pages/Deposits/list';
import NewDeposit from '../pages/Deposits/new';

import Devices from '../pages/Devices/list';
import NewDevice from '../pages/Devices/new';

import Locations from '../pages/Locations/list';
import NewLocation from '../pages/Locations/new';

import Materials from '../pages/Materials/list';
import NewMaterial from '../pages/Materials/new';
import EditMaterial from '../pages/Materials/edit';

import MeasurementUnit from '../pages/Materials/MeasurementUnits/list';
import NewMeasurementUnit from '../pages/Materials/MeasurementUnits/new';

import MaterialTypes from '../pages/Materials/Type/list';
import NewMaterialTypes from '../pages/Materials/Type/new';

import MaterialImport from '../pages/Materials/Import';
import EditMeasurementUnit from '../pages/Materials/MeasurementUnits/edit';
import MaterialImportsList from '../pages/Materials/MaterialsImport';
import EditMaterialTypes from '../pages/Materials/Type/edit';
import EditUser from '../pages/Users/edit';
import EditDeposits from '../pages/Deposits/edit';
import EditCostCenter from '../pages/CostCenters/edit';
import EditLocation from '../pages/Locations/edit';
import EditDevice from '../pages/Devices/edit';
import ListMaterialTake from '../pages/MaterialTake/list';
import ViewMaterialsTakenItems from '../pages/MaterialTake/view';
import Logs from '../pages/Users/logs';
import Log from '../pages/Users/log';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />

    <Route
      path="/dashboard"
      permissions={['ADMIN']}
      isPrivate
      component={Dashboard}
    />

    <Route
      path="/retiradas"
      exact
      permissions={['ADMIN']}
      isPrivate
      component={ListMaterialTake}
    />

    <Route
      path="/retiradas/novo"
      permissions={['ADMIN', 'MECHANICAL']}
      isPrivate
      exact
      component={MaterialTake}
    />

    <Route
      path="/retiradas/:id"
      exact
      permissions={['ADMIN']}
      isPrivate
      component={ViewMaterialsTakenItems}
    />

    <Route
      path="/usuarios"
      permissions={['ADMIN']}
      exact
      isPrivate
      component={Users}
    />
    <Route
      path="/usuarios/novo"
      permissions={['ADMIN']}
      isPrivate
      component={NewUser}
    />
    <Route
      path="/usuarios/:id"
      permissions={['ADMIN']}
      isPrivate
      component={EditUser}
    />

    <Route
      path="/unidades-medida"
      exact
      permissions={['ADMIN']}
      isPrivate
      component={MeasurementUnit}
    />
    <Route
      path="/unidades-medida/novo"
      permissions={['ADMIN']}
      isPrivate
      component={NewMeasurementUnit}
    />
    <Route
      path="/unidades-medida/:id"
      permissions={['ADMIN']}
      isPrivate
      component={EditMeasurementUnit}
    />
    <Route
      path="/centros-custo"
      exact
      permissions={['ADMIN']}
      isPrivate
      component={CostCenters}
    />
    <Route
      path="/centros-custo/novo"
      permissions={['ADMIN']}
      isPrivate
      component={NewCostCenter}
    />
    <Route
      path="/centros-custo/:id"
      permissions={['ADMIN']}
      isPrivate
      component={EditCostCenter}
    />
    <Route
      path="/depositos"
      exact
      permissions={['ADMIN']}
      isPrivate
      component={Deposits}
    />
    <Route
      path="/depositos/novo"
      permissions={['ADMIN']}
      isPrivate
      component={NewDeposit}
    />
    <Route
      path="/depositos/:id"
      permissions={['ADMIN']}
      isPrivate
      component={EditDeposits}
    />

    <Route
      path="/dispositivos"
      exact
      permissions={['ADMIN']}
      isPrivate
      component={Devices}
    />
    <Route
      path="/dispositivos/novo"
      permissions={['ADMIN']}
      isPrivate
      component={NewDevice}
    />
    <Route
      path="/dispositivos/:id"
      permissions={['ADMIN']}
      isPrivate
      component={EditDevice}
    />
    <Route
      path="/localizacoes"
      exact
      permissions={['ADMIN']}
      isPrivate
      component={Locations}
    />
    <Route
      path="/localizacoes/novo"
      permissions={['ADMIN']}
      isPrivate
      component={NewLocation}
    />
    <Route
      path="/localizacoes/:id"
      permissions={['ADMIN']}
      isPrivate
      component={EditLocation}
    />
    <Route
      path="/materiais/arquivos/:id"
      permissions={['ADMIN']}
      exact
      isPrivate
      component={MaterialImportsList}
    />
    <Route
      path="/materiais/importar"
      permissions={['ADMIN']}
      isPrivate
      exact
      component={MaterialImport}
    />
    <Route
      path="/materiais"
      exact
      permissions={['ADMIN']}
      isPrivate
      component={Materials}
    />
    <Route
      path="/materiais/novo"
      permissions={['ADMIN']}
      isPrivate
      exact
      component={NewMaterial}
    />
    <Route
      path="/materiais/:id"
      permissions={['ADMIN']}
      exact
      isPrivate
      component={EditMaterial}
    />
    <Route
      path="/tipos-material"
      exact
      permissions={['ADMIN']}
      isPrivate
      component={MaterialTypes}
    />
    <Route
      path="/tipos-material/novo"
      permissions={['ADMIN']}
      isPrivate
      component={NewMaterialTypes}
    />
    <Route
      path="/tipos-material/:id"
      permissions={['ADMIN']}
      isPrivate
      component={EditMaterialTypes}
    />
    <Route
      path="/logs"
      permissions={['ADMIN']}
      isPrivate
      exact
      component={Logs}
    />
    <Route path="/logs/:id" permissions={['ADMIN']} isPrivate component={Log} />
  </Switch>
);

export default Routes;
