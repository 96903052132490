import { EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IMaterialTypeDTO from '../../../../dtos/IMaterialTypeDTO';
import { useApi } from '../../../../hooks/api';
import { Actions, Container } from './styles';

const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
];

const ListMaterialTypes: React.FC = () => {
  const { api } = useApi();
  const history = useHistory();
  const [materialTypes, setMaterialTypes] = useState<IMaterialTypeDTO[]>();
  const [xCount, setXCount] = useState<number>();

  const listMaterialTypes = useCallback(
    async (skip = 0): Promise<void> => {
      const response = await api.get<IMaterialTypeDTO[]>(
        `materialTypes?skip=${skip}`,
      );

      const data = response.data.map((materialType) => ({
        key: materialType.id,
        ...materialType,
      }));

      setXCount(Number(response.headers['x-count']) || 0);
      setMaterialTypes(data);
    },
    [api],
  );

  useEffect(() => {
    listMaterialTypes();
  }, [listMaterialTypes]);

  const handleNavigate = (to: string): void => {
    history.push(to);
  };

  return (
    <Container>
      <h1>Tipos de Material</h1>
      <Table
        columns={[
          ...columns,
          {
            title: '#',
            dataIndex: '#',
            key: '#',
            render: (_: string, record: IMaterialTypeDTO) => {
              return (
                <Actions>
                  <Button
                    title="Editar"
                    type="primary"
                    onClick={() =>
                      handleNavigate(`/tipos-material/${record.id}`)
                    }
                  >
                    <EditOutlined />
                  </Button>
                  {/* <Button danger title="Excluir" disabled>
                    <DeleteFilled />
                  </Button> */}
                </Actions>
              );
            },
          },
        ]}
        dataSource={materialTypes}
        pagination={{
          total: xCount,
          onChange: (page) => listMaterialTypes((page - 1) * 10),
          pageSizeOptions: [10],
        }}
      />
    </Container>
  );
};

export default ListMaterialTypes;
