import { Form, Input, Divider, Select, Button } from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import IUserDTO from '../../../dtos/IUserDTO';
import { useApi } from '../../../hooks/api';
import { useNotification } from '../../../hooks/notification';
import { Container } from './styles';

const validate = {
  required: '${label} é obrigatório',
  types: {
    email: 'Informe um e-mail válido',
  },
};

const { Option } = Select;

const NewUser: React.FC = () => {
  const { api } = useApi();
  const { openNotificationWithIcon } = useNotification();
  const [userType, setUserType] = useState('');

  const [form] = Form.useForm();

  const handleSubmit = async (data: IUserDTO): Promise<void> => {
    try {
      await api.post('users', data);
      openNotificationWithIcon('success', {
        title: 'Usuário',
        content: 'Usuário cadastrado com sucesso!',
      });
      form.resetFields();
    } catch (error) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data.messageResponse;
      }
      openNotificationWithIcon('error', {
        title: 'Usuário',
        content: errorMessage || 'Erro ao cadastrar o usuário!',
      });
    }
  };

  return (
    <Container>
      <h1>Cadastro de Usuário</h1>
      <Form
        size="large"
        labelCol={{ flex: '110px' }}
        labelAlign="left"
        wrapperCol={{ flex: 1 }}
        labelWrap
        validateMessages={validate}
        onFinish={handleSubmit}
        form={form}
        colon={false}
      >
        <Form.Item
          name={['name']}
          label="Nome"
          rules={[{ required: true }]}
          wrapperCol={{ span: 12 }}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['email']}
          label="E-mail"
          rules={[{ required: true, type: 'email' }]}
          wrapperCol={{ span: 8 }}
          hasFeedback
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item
          name={['enrollment']}
          label="Matrícula"
          rules={[{ required: true }]}
          wrapperCol={{ span: 2 }}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['type']}
          label="Tipo de usuário"
          rules={[{ required: true }]}
          wrapperCol={{ span: 6 }}
          hasFeedback
        >
          <Select onChange={(value) => setUserType(value)}>
            <Option value="ADMIN">Administrador</Option>
            <Option value="MECHANICAL">Mecânico</Option>
          </Select>
        </Form.Item>

        {userType && userType === 'ADMIN' && (
          <>
            <Divider style={{ paddingTop: '50px' }} orientation="left">
              Dados de acesso:
            </Divider>
            <Form.Item
              name={['password']}
              label="Senha"
              rules={[{ required: true }]}
              wrapperCol={{ span: 6 }}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name={['password_confirmation']}
              dependencies={['password']}
              label="Confirme a senha"
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        'As duas senhas que você digitou não correspondem!',
                      ),
                    );
                  },
                }),
              ]}
              wrapperCol={{ span: 6 }}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default NewUser;
