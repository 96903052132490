/* eslint-disable jsx-a11y/anchor-is-valid */
import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Switch,
  Table,
  Typography,
} from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IAccessPointDTO from '../../../dtos/IAccessPointDTO';
import IDeviceDTO from '../../../dtos/IDeviceDTO';
import { useApi } from '../../../hooks/api';
import { useNotification } from '../../../hooks/notification';
import { Actions, Container } from './styles';

const columns = [
  {
    title: 'Nome',
    dataIndex: 'nome',
    key: 'nome',
  },
  {
    title: 'IP',
    dataIndex: 'ip',
    key: 'ip',
  },
  {
    title: 'Modelo',
    dataIndex: 'modelo_dispositivo',
    key: 'modelo_dispositivo',
    render: (model: IDeviceDTO['modelo_dispositivo']) => model.nome_modelo,
  },
];

interface IDeviceResponse extends IDeviceDTO {
  data: IDeviceDTO[];
}

interface DeviceDTO extends Omit<IDeviceDTO, 'pontos_acesso'> {
  key: number;
  pontos_acesso: {
    id: number;
    key: number;
    nome_porta: string;
    num_porta: number;
  }[];
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: IDeviceDTO;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ListDevices: React.FC = () => {
  const { api } = useApi();
  const { openNotificationWithIcon } = useNotification();

  const [form] = Form.useForm();
  const history = useHistory();
  const [editingKey, setEditingKey] = useState<number | undefined>();
  const [inMaintenance, setInMaintenance] = useState(false);
  const [devices, setDevices] = useState<DeviceDTO[]>([]);

  const listDevices = useCallback(async (): Promise<void> => {
    const response = await api.get<IDeviceResponse>('devices');

    const data = response.data.data.map((device) => ({
      key: device.id,
      ...device,
      pontos_acesso: device.pontos_acesso.map((p) => ({
        key: p.id,
        ...p,
      })),
    }));

    setDevices(data);
  }, [api]);

  useEffect(() => {
    listDevices();
  }, [listDevices]);

  const isEditing = (record: IAccessPointDTO): boolean =>
    record.id === editingKey;

  const edit = (record: Partial<IAccessPointDTO>): void => {
    // const fieldsValue = { name: '', ...record };
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = (): void => {
    setEditingKey(undefined);
  };

  const handleSubmit = async (record: IAccessPointDTO): Promise<void> => {
    try {
      const row = (await form.validateFields()) as IAccessPointDTO;

      await api.put(`accessPoints/${record.id}`, {
        nome_porta: row.nome_porta,
      });

      openNotificationWithIcon('success', {
        title: 'Dispositivo',
        content: 'Dispositivo atualizado com sucesso!',
      });
      setEditingKey(undefined);
    } catch (error) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data.messageResponse;
      }
      openNotificationWithIcon('error', {
        title: 'Dispositivo',
        content: errorMessage || 'Erro ao atualizar o dispositivo',
      });
    }
  };

  const handleOpenToMaintenance = async (
    accessPointId: number,
  ): Promise<void> => {
    try {
      const maintenance = !inMaintenance;
      await api.get(
        `accessPoints/${accessPointId}/manutencao?manutencao=${maintenance}`,
      );
      setInMaintenance(maintenance);
    } catch (error) {
      openNotificationWithIcon('error', {
        title: 'Dispositivo',
        content: 'Erro ao abrir dispositivo para manutenção',
      });
    }
  };

  const expandedRowRender = (record: DeviceDTO): JSX.Element => {
    const expandedColumns = [
      {
        title: 'Nome',
        dataIndex: 'nome_porta',
        key: 'nome_porta',
        editable: true,
      },
      {
        title: 'Porta',
        dataIndex: 'num_porta',
        key: 'num_porta',
        editable: false,
      },
      {
        title: 'Abrir para manutenção',
        dataIndex: '#',
        key: '#',
        render: (_: string, accessPoint: IAccessPointDTO) => {
          return (
            <Actions>
              <Switch
                onChange={() => handleOpenToMaintenance(accessPoint.id)}
              />
            </Actions>
          );
        },
      },
      {
        title: '#',
        dataIndex: 'actions',
        key: 'actions',
        render: (_: string, item: IAccessPointDTO) => {
          const editable = isEditing(item);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => handleSubmit(item)}
                style={{ marginRight: 8 }}
              >
                Salvar
              </Typography.Link>
              <Popconfirm
                title="Cancelar edição?"
                onConfirm={cancel}
                cancelText="Cancelar"
              >
                <a href="#">Cancelar</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link
              disabled={editingKey !== undefined}
              onClick={() => edit(item)}
            >
              Editar
            </Typography.Link>
          );
        },
      },
    ];

    const mergedColumns = expandedColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (rec: IAccessPointDTO) => ({
          record: rec,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(rec),
        }),
      };
    });

    return (
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          dataSource={record.pontos_acesso}
          pagination={false}
        />
      </Form>
    );
  };

  const handleNavigate = (to: string): void => {
    history.push(to);
  };

  return (
    <Container>
      <h1>Dispositivos</h1>
      <Table
        columns={[
          ...columns,
          {
            title: '#',
            dataIndex: '#',
            key: '#',
            render: (_: string, record: IDeviceDTO) => {
              return (
                <Actions>
                  <Button
                    title="Editar"
                    type="primary"
                    onClick={() => handleNavigate(`/dispositivos/${record.id}`)}
                  >
                    <EditOutlined />
                  </Button>
                  {/* <Button danger title="Excluir">
                    <DeleteFilled />
                  </Button> */}
                </Actions>
              );
            },
          },
        ]}
        dataSource={devices}
        expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
      />
    </Container>
  );
};

export default ListDevices;
