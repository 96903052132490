import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Button, Radio, Space, Select } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import IAccessPointDTO from '../../../dtos/IAccessPointDTO';
import ILocationDTO from '../../../dtos/ILocationDTO';
import { useApi } from '../../../hooks/api';
import { useNotification } from '../../../hooks/notification';
import { Container } from './styles';

const validate = {
  required: '${label} é obrigatório',
};

const EditLocation: React.FC = () => {
  const { api } = useApi();
  const { openNotificationWithIcon } = useNotification();
  const [location, setLocation] = useState<ILocationDTO>();
  const [hasDrawer, setHasDrawer] = useState(false);
  const [accessPoints, setAccessPoints] = useState<IAccessPointDTO[]>([]);

  const [form] = Form.useForm();

  const { id } = useParams<{ id: string }>();

  const loadData = useCallback(async (): Promise<void> => {
    try {
      const locationResponse = await api.get<ILocationDTO>(`locations/${id}`);
      const hasDevice = locationResponse.data.drawers?.find(
        (drawer) => !!drawer.deviceId,
      );

      if (hasDevice) {
        const accessPointsResponse = await api.get<IAccessPointDTO[]>(
          'accessPoints',
        );
        setAccessPoints(accessPointsResponse.data);
      }

      setHasDrawer(locationResponse.data.hasDrawer);
      setLocation(locationResponse.data);
    } catch (error) {
      openNotificationWithIcon('error', {
        title: 'Error',
        content: 'Ocorreu um erro ao listar os dados!',
      });
    }
  }, [api, id, openNotificationWithIcon]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleAccessPointSelected = (name: number, value: any): void => {
    if (value) {
      const accessPointSelected = accessPoints.find((a) => a.id === value);

      if (!accessPointSelected || !accessPointSelected.num_porta) {
        openNotificationWithIcon('error', {
          title: 'Localização',
          content: 'Fechadura com cadastro incompleto!',
        });
        return;
      }

      form.setFieldValue(
        ['drawers', name, 'port'],
        accessPointSelected?.num_porta,
      );
    } else {
      form.setFieldValue(['drawers', name, 'port'], 0);
    }
  };

  const handleSubmit = async (data: ILocationDTO): Promise<void> => {
    try {
      await api.put(`locations/${id}`, data);

      openNotificationWithIcon('success', {
        title: 'Localização',
        content: 'Localização atualizada com sucesso!',
      });
    } catch (error) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data.messageResponse;
      }
      openNotificationWithIcon('error', {
        title: 'Localização',
        content: errorMessage || 'Erro ao atualizar a localização!',
      });
    }
  };

  return (
    <Container>
      <h1>Atualizar Localização de Materiais</h1>
      {location && (
        <Form
          form={form}
          initialValues={location}
          onFinish={handleSubmit}
          autoComplete="off"
          validateMessages={validate}
          size="large"
          labelCol={{ flex: '115px' }}
          labelAlign="left"
          wrapperCol={{ flex: 1 }}
          labelWrap
          colon={false}
        >
          <Form.Item
            name="description"
            label="Descrição"
            rules={[{ required: true }]}
            wrapperCol={{ span: 12 }}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="hasDrawer"
            label="Possui gavetas ?"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Radio.Group onChange={(e) => setHasDrawer(e.target.value)}>
              <Radio value>Sim</Radio>
              <Radio value={false}>Não</Radio>
            </Radio.Group>
          </Form.Item>
          {hasDrawer && (
            <Form.List name="drawers">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...field }) => (
                    <Space key={key} align="baseline">
                      <Form.Item
                        {...field}
                        label="Descrição"
                        name={[name, 'name']}
                        rules={[{ required: true }]}
                        hasFeedback
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="Compartimentos"
                        name={[name, 'compartments']}
                        initialValue={0}
                      >
                        <Input type="number" />
                      </Form.Item>

                      <Form.Item noStyle shouldUpdate>
                        <Form.Item
                          {...field}
                          label="Fechadura"
                          name={[name, 'deviceId']}
                          hasFeedback
                        >
                          <Select
                            style={{ width: '250px', marginRight: '30px' }}
                            onChange={(value) =>
                              handleAccessPointSelected(name, value)
                            }
                          >
                            <Select.Option value={undefined} key={undefined}>
                              Sem fechadura
                            </Select.Option>
                            {accessPoints.map((accessPoint) => (
                              <Select.Option
                                value={accessPoint.id}
                                key={accessPoint.id}
                              >
                                {accessPoint.nome_porta}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Form.Item>

                      <Form.Item
                        {...field}
                        label="Porta"
                        name={[name, 'port']}
                        style={{ display: 'none' }}
                      >
                        <Input type="number" />
                      </Form.Item>

                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}

                  <Form.Item wrapperCol={{ span: 12 }}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Adicionar gaveta
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
          </Form.Item>
        </Form>
      )}
    </Container>
  );
};

export default EditLocation;
