import { Form, Input, Button, Select, Divider } from 'antd';
import { AxiosError } from 'axios';
import IDeviceDTO from '../../../dtos/IDeviceDTO';
import { useApi } from '../../../hooks/api';
import { useNotification } from '../../../hooks/notification';
import { Container } from './styles';

const validate = {
  required: '${label} é obrigatório',
};

const { Option } = Select;

const NewDevices: React.FC = () => {
  const { api } = useApi();
  const { openNotificationWithIcon } = useNotification();
  const [form] = Form.useForm();

  const handleSubmit = async (data: IDeviceDTO): Promise<void> => {
    try {
      await api.post('devices', data);

      openNotificationWithIcon('success', {
        title: 'Dispositivo',
        content: 'Dispositivo cadastrado com sucesso!',
      });
      form.resetFields();
    } catch (error) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data.messageResponse;
      }
      openNotificationWithIcon('error', {
        title: 'Dispositivo',
        content: errorMessage || 'Erro ao cadastrar o dispositivo!',
      });
    }
  };

  return (
    <Container>
      <h1>Cadastro de Dispositivo</h1>
      <Form
        size="large"
        labelCol={{ flex: '110px' }}
        labelAlign="left"
        wrapperCol={{ flex: 1 }}
        labelWrap
        colon={false}
        validateMessages={validate}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          name={['nome']}
          label="Nome"
          rules={[{ required: true }]}
          wrapperCol={{ span: 6 }}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={['modelo_dispositivo', 'id']}
          label="Modelo"
          rules={[{ required: true }]}
          wrapperCol={{ span: 6 }}
          hasFeedback
        >
          <Select>
            <Option value="1">CT 500 1P</Option>
            <Option value="2">CT 500 2P</Option>
            <Option value="3">CT 500 4P</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name={['ip']}
          label="Endereço de IP"
          rules={[{ required: true }]}
          wrapperCol={{ span: 4 }}
          hasFeedback
        >
          <Input placeholder="ex: 192.168.0.1" />
        </Form.Item>

        <Divider />

        <Button type="primary" htmlType="submit">
          Salvar
        </Button>
      </Form>
    </Container>
  );
};

export default NewDevices;
