import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ILocationDTO from '../../../dtos/ILocationDTO';
import { useApi } from '../../../hooks/api';
import { Actions, Container } from './styles';

const columns = [
  {
    title: 'Descrição',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Possui gavetas?',
    dataIndex: 'hasDrawer',
    key: 'hasDrawer',
    render: (hasDrawer: boolean) => (hasDrawer ? 'sim' : 'não'),
  },
];

const ListLocations: React.FC = () => {
  const { api } = useApi();
  const [locations, setLocations] = useState<ILocationDTO[]>();
  const [search, setSearch] = useState('');
  const [xCount, setXCount] = useState(0);

  const history = useHistory();

  const listLocations = useCallback(
    async (skip = 0): Promise<void> => {
      const response = await api.get<ILocationDTO[]>(
        `locations?search=${search}&skip=${skip}`,
      );

      const data = response.data.map((location) => ({
        key: location.id,
        ...location,
      }));

      setXCount(Number(response.headers['x-count']) || 0);
      setLocations(data);
    },
    [api, search],
  );

  useEffect(() => {
    listLocations();
  }, [listLocations]);

  const handleNavigate = (to: string): void => {
    history.push(to);
  };

  return (
    <Container>
      <h1>Localizações de Materiais</h1>
      <Input.Search
        placeholder="Buscar por descrição"
        onSearch={(value) => setSearch(value)}
        style={{ width: 400, marginBottom: 20 }}
      />
      <Table
        columns={[
          ...columns,
          {
            title: '#',
            dataIndex: '#',
            key: '#',
            render: (_: string, record: ILocationDTO) => {
              return (
                <Actions>
                  <Button
                    title="Editar"
                    type="primary"
                    onClick={() => handleNavigate(`/localizacoes/${record.id}`)}
                  >
                    <EditOutlined />
                  </Button>
                  {/* <Button danger title="Excluir">
                    <DeleteFilled />
                  </Button> */}
                </Actions>
              );
            },
          },
        ]}
        dataSource={locations}
        pagination={{
          total: xCount,
          onChange: (page) => listLocations((page - 1) * 10),
          pageSizeOptions: [10],
        }}
      />
    </Container>
  );
};

export default ListLocations;
